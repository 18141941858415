import { useEffect, useState } from "react";
import moment from "moment";

import {
  Drawer,
  Form,
  Input,
  Button,
  Radio,
  Space,
  notification,
  DatePicker,
} from "antd";
import Request from "../../Requests";

import UploadComponent from "../../components/drawer/UploadComponent";

export default function BannerAdDrawer({ open, onClose, getData, current }) {
  const [loading, setLoading] = useState(false);
  const [errorMess, setErrorMess] = useState(null);

  const [bannerAdType, setBannerAdType] = useState("text");

  const [form] = Form.useForm();
  const [adImage, setAdImage] = useState({
    file: null,
    url: "",
  });

  const [adVideo, setAdVideo] = useState(null);
  const [adTitle, setAdTitle] = useState("");
  const [adDescription, setAdDescription] = useState("");

  useEffect(() => {
    setErrorMess(null);
    if (!open) {
      form.resetFields();
      setLoading(false);
      setErrorMess(null);
      setAdDescription(null);
      setAdTitle(null);
      setAdImage(null);
      setAdVideo(null);
      setBannerAdType("text");
    }
  }, [open, adImage?.url, adVideo?.url]);

  useEffect(() => {
    if (current) {
      setBannerAdType(current?.type);
      form.setFields([{ name: "schedule", value: current.schedule }]);
      switch (current?.type) {
        case "text":
          form.setFields([
            { name: "title", value: current.title },
            { name: "description", value: current.description },
          ]);
          setAdImage({ file: null, url: current.image });
          setAdDescription(current?.description);
          setAdTitle(current.title);
          break;
        case "image":
          setAdImage({ file: null, url: current.image });
          break;
        case "video":
          setAdVideo({ file: null, url: current.video });
          break;
        default:
          break;
      }
    }
  }, [open]);

  const onChangeActive = (e) => {
    setBannerAdType(e.target.value);
  };

  const onFieldsChange = (changedField, allFields) => {
    switch (changedField[0].name[0]) {
      case "title":
        setAdTitle(changedField[0].value);
        break;
      case "description":
        setAdDescription(changedField[0].value);
        break;
      default:
        break;
    }
  };

  const onFinish = (values) => {
    if (!current) {
      return;
    }
    setLoading(true);
    setErrorMess(null);

    const formData = new FormData();

    switch (bannerAdType) {
      case "text":
        if (adImage?.file) {
          formData.append("image", adImage?.file);
        }
        if (values?.title) {
          formData.append("title", values?.title);
        }
        if (values?.description) {
          formData.append("description", values?.description);
        }
        break;
      case "image":
        if (adImage?.file) {
          formData.append("image", adImage?.file);
        }
        break;
      case "video":
        if (adVideo?.file) {
          formData.append("video", adVideo?.file);
        }
        break;
      default:
        break;
    }

    formData.append("schedule", values?.schedule);
    formData.append("type", bannerAdType);
    formData.append("id", Number(current.id));

    Request.banner_ad
      .edit(formData)
      .then((res) => {
        setLoading(false);
        notification.success({ description: res });
        getData();
        form.resetFields();
        onClose();
      })
      .catch((err) => {
        setLoading(false);
        // setErrorMess(err);
      });
  };

  return (
    <Drawer
      forceRender
      open={open}
      onClose={onClose}
      placement="right"
      title="Edit Banner AD"
      className="add-new-banner-drawer"
      size="large"
    >
      <div className="banner-ad-container">
        <div>
          <Radio.Group onChange={onChangeActive} value={bannerAdType}>
            <Radio.Button value="text">Customize Layout</Radio.Button>
            <Radio.Button value="image">Add Image</Radio.Button>
            <Radio.Button value="video">Add Video</Radio.Button>
          </Radio.Group>
          <div className="form-banner-wrap">
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onFieldsChange={onFieldsChange}
            >
              {bannerAdType === "text" ? (
                <>
                  <Form.Item label="Upload image" name="image">
                    <UploadComponent
                      setFileUrl={setAdImage}
                      fileUrl={adImage?.url}
                      acceptType={"image/*"}
                    />
                  </Form.Item>
                  <Form.Item label="Title" name="title">
                    <Input showCount maxLength={20} />
                  </Form.Item>
                  <Form.Item label="Description" name="description">
                    <Input.TextArea
                      rows={4}
                      showCount
                      maxLength={320}
                      style={{
                        height: 120,
                        resize: "none",
                      }}
                    />
                  </Form.Item>
                </>
              ) : (
                <Form.Item
                  label={
                    bannerAdType === "image" ? "Upload image" : "Upload video"
                  }
                  name={bannerAdType === "image" ? "image" : "video"}
                >
                  <UploadComponent
                    setFileUrl={
                      bannerAdType === "image" ? setAdImage : setAdVideo
                    }
                    fileUrl={
                      bannerAdType === "image" ? adImage?.url : adVideo?.url
                    }
                    acceptType={
                      bannerAdType === "image" ? "image/*" : "video/*"
                    }
                  />
                </Form.Item>
              )}

              <Form.Item
                label="Date"
                name="schedule"
                rules={[
                  {
                    required: !current,
                    message: "Please enter date",
                  },
                ]}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value="daily"> 1 Day ($3) </Radio>
                    <Radio value="weekly"> 1 Week ($5) </Radio>
                    <Radio value="monthly"> 1 Month ($20) </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>

              <Form.Item>
                <div className="form-btn">
                  <Button
                    type="primary"
                    style={{ width: "120px" }}
                    onClick={() => form.submit()}
                    loading={loading}
                    // disabled={disabled}
                  >
                    Save
                  </Button>
                </div>
              </Form.Item>
            </Form>
            {errorMess && <p style={{ color: "#ea4a50" }}>{errorMess}</p>}
          </div>
        </div>
      </div>
    </Drawer>
  );
}
