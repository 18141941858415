import { useState, useEffect, useRef } from "react";

import { Table } from "antd";

import { getColumnSearchProps } from "../../configs/config";

import {
    TableSettingsButton,
    TableResetButton,
    TableText,
    TableCSV,
    RangePickerFilterDropdown,
    TableDateHHMMSS,
    TitlePage,
} from "../../components";

import Request from "../../Requests";

import { generateQuery } from "../../Utils";

import useColumns from "../../hooks/useColumns";

import useHistorySettings from "../../hooks/useHistorySettings";

export default function BillingHistoryPage({ theProviderId }) {
    const fieldsType = {
        payment_method: "STRING",
        amount: "INTEGER",
        transaktion_id: "STRING",
        currency: "STRING",
        status: "ENUM",
        provider_id: "INTEGER",
        createdAt: "DATE",
        updatedAt: "DATE",
    };

    const defaultTableState = {
        filteredInfo: {},
        sortedInfo: {},
        page: 1,
        limit: 20,
    };

    const searchInputRef = useRef(null);

    const [data, setData] = useState([]);

    const [dataForExport, setDataForExport] = useState([]);

    const [loading, setLoading] = useState(false);

    const [tableState, setTableState] = useState(defaultTableState);

    const [total, setTotal] = useState(0);

    const [columns, setColumns] = useState([
        {
            title: "#",
            fixed: "left",
            key: "row__index",
            render: (text, record, index) => record.index,
        },

        {
            title: "Payment method",
            dataIndex: "payment_method",
            key: "payment_method",
            render: (text, record, index) => (
                <TableText
                    text={(
                        text?.charAt(0)?.toUpperCase() + text?.slice(1)?.toLowerCase()
                    )?.replaceAll("_", " ")}
                />
            ),
            ...getColumnSearchProps(searchInputRef),
        },

        {
            title: "Credit",
            dataIndex: "credit",
            key: "credit",
            render: (text, record, index) => text,
            sorter: true,
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render: (text, record, index) => <TableText text={record.amount ? text : "N/A"} />,
            sorter: true,
        },
        {
            title: "Transaction ID",
            dataIndex: "transaktion_id",
            key: "transaktion_id",
            render: (text, record, index) => (
                <TableText text={record.transaktion_id ? text : "N/A"} />
            ),
            ...getColumnSearchProps(searchInputRef),
        },
        {
            title: "Currency",
            dataIndex: "currency",
            key: "currency",
            render: (text, record, index) => <TableText text={record.currency ? text : "N/A"} />,
            ...getColumnSearchProps(searchInputRef),
        },

        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text, record, index) => <TableText text={record.currency ? text : "N/A"} />,
            filters: [
                { text: "COMPLETED", value: "COMPLETED" },
                { text: "PENDING", value: "PENDING" },
            ],
        },

        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            render: (text, record, index) => <TableText text={text} />,
        },
        {
            title: "Updated date",
            dataIndex: "updatedAt",
            key: "updatedAt",
            align: "center",
            render: (text, record, index) => (text ? <TableDateHHMMSS date={text} /> : "N/A"),
            sorter: true,
            filterDropdown: RangePickerFilterDropdown,
        },

        {
            title: "Created date",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            render: (text, record, index) => (text ? <TableDateHHMMSS date={text} /> : "N/A"),
            sorter: true,
            filterDropdown: RangePickerFilterDropdown,
        },
    ]);
    const [onChangeColumns] = useColumns(columns, setColumns, "billing_history");

    const getData = () => {
        let query = generateQuery(tableState, fieldsType);

        if (theProviderId) {
            let providerQuery = JSON.parse(query.query);
            providerQuery = {
                ...providerQuery,
                filter: { provider_id: theProviderId },
            };
            query.query = JSON.stringify(providerQuery);
        }

        setLoading(true);

        Request.billing_history
            .get(query)
            .then((data) => {
                if (!data.rows.length && tableState.page > 1) {
                    return setTableState({ ...tableState, page: tableState.page - 1 });
                }

                setLoading(false);
                setData(
                    data.rows.map((item, index) => ({
                        index: (tableState.page - 1) * tableState.limit + index + 1,
                        ...item,
                    }))
                );
                setTotal(data.total);
            })
            .catch(({ message }) => {
                setLoading(false);
            });
    };

    const tableOnChange = (pagination, filters, sorter) => {
        let { order, field } = sorter;

        setTableState({
            filteredInfo: filters,
            sortedInfo: { order, field },
            page: pagination.current,
            limit: pagination.pageSize,
        });
    };

    const resetTable = () => {
        setTableState(defaultTableState);
    };

    useEffect(() => {
        let _columns = [...columns];
        let { filteredInfo, sortedInfo } = tableState;

        _columns.map((column) => {
            column.filteredValue = filteredInfo[column.key] || null;
            column.sortOrder = sortedInfo.field === column.key && sortedInfo.order;
        });

        setColumns(_columns);
        const timeout = setTimeout(() => {
            getData();
        }, 500);
        return () => clearTimeout(timeout);
    }, [tableState]);

    const onExport = (selected) => {
        if (selected == "allData") {
            Request.billing_history
                .get({ query: JSON.stringify({ pagination: 0 }) })
                .then((data) => {
                    setDataForExport(data);
                })
                .catch(({ message }) => {});
        }
        if (selected == "data") {
            setDataForExport(data);
        }
    };

    return (
        <div>
            {!theProviderId && <TitlePage title="Resellers billing history" />}
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 8,
                    marginBottom: 15,
                }}
            >
                <div>
                    <TableResetButton onClick={resetTable} />
                    <TableSettingsButton columns={columns} onChangeColumns={onChangeColumns} />
                    <TableCSV dataForExport={dataForExport} onExport={onExport} />
                </div>
            </div>
            <Table
                loading={loading}
                rowKey="id"
                columns={columns.filter((column) => column.visible)}
                dataSource={data}
                scroll={{
                    x: "max-content",
                    y: null,
                }}
                onChange={tableOnChange}
                size="small"
                pagination={{
                    position: ["bottomCenter"],
                    current: tableState.page,
                    total: total,
                    pageSize: tableState.limit,
                    showSizeChanger: true,
                }}
                bordered
            />
        </div>
    );
}
