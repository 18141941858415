import { useState, useEffect, useRef } from "react";

import { Table, notification } from "antd";

import Request from "../../Requests";

import { generateQuery } from "../../Utils";

import useColumns from "../../hooks/useColumns";
import useHistorySettings from "../../hooks/useHistorySettings";
import { getColumnSearchProps } from "../../configs/config";

import {
  TableAddButton,
  TableActions,
  TableText,
  TitlePage,
  TableDate,
  TableBoolean,
  TableDateHHMMSS,
  RangePickerFilterDropdown,
} from "../../components";

import AlertsDrawer from "./Drawer";

export default function AlertsPage() {
  const fieldsType = {
    email: "STRING",
    brand_name: "STRING",
    country: "STRING",
    desc: "STRING",
    send_from: "DATE",
    send_to: "DATE",
    is_enable: "BOOLEAN",
    createdAt: "DATE",
    updatedAt: "DATE",
  };

  const defaultTableState = {
    filteredInfo: {},
    sortedInfo: {},
    page: 1,
    limit: 20,
  };
  const searchInputRef = useRef(null);

  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const [data, setData] = useState([]);

  const [selected, setSelected] = useState(null);


  const [loading, setLoading] = useState(false);

  const [tableState, setTableState] = useHistorySettings(defaultTableState);

  const [total, setTotal] = useState(0);

  const [columns, setColumns] = useState([
    {
      title: "#",
      fixed: "left",
      key: "row__index",
      width: 60,
      align: "center",
      render: (text, record, index) => record.index,
    },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: 60,
      align: "center",
      render: (text, record, index) => text,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (text, record, index) => <TableText text={text} />,
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
      align: "center",
      render: (text, record, index) => <TableText text={text} />,
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      align: "center",
      render: (text, record, index) => <TableText text={text} />,
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Description",
      dataIndex: "desc",
      key: "desc",
      width: 130,
      align: "center",
      render: (text, record, index) => <TableText text={text} />,
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Enable",
      dataIndex: "is_enable",
      key: "is_enable",
      width: 100,
      align: "center",
      render: (text, record, index) => <TableBoolean value={text} />,
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
    },
    {
      title: "Seen status",
      align: "center",
      children: [
        {
          title: "All send",
          dataIndex: "total",
          key: "total",
          render: (text, record, index) => <TableText text={record?.total} />,
        },

        {
          title: "Seen",
          dataIndex: "viewed",
          key: "viewed",
          render: (text, record, index) => <TableText text={record?.viewed} />,
        },
      ],
    },
    {
      title: "Registered Date",
      align: "center",
      children: [
        {
          title: "Send from",
          dataIndex: "send_from",
          key: "send_from",
          width: 130,
          align: "center",
          render: (text, record, index) => <TableDate date={text} />,
        },
        {
          title: "Send to",
          dataIndex: "send_to",
          key: "send_to",
          width: 130,
          align: "center",
          render: (text, record, index) => <TableDate date={text} />,
        },
      ],
    },
    {
      title: "Updated date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
      sorter: true,
      filterDropdown: RangePickerFilterDropdown,
    },

    {
      title: "Created date",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
      sorter: true,
      filterDropdown: RangePickerFilterDropdown,
    },
    {
      title: "Actions",
      key: "row__actions",
      fixed: "right",
      width: 100,
      render: (text, record, index) => (
        <TableActions
          onEdit={showDrawer}
          onDelete={deleteData}
          record={record}
        />
      ),
    },
  ]);

  const [onChangeColumns] = useColumns(columns, setColumns, "alerts");

  const getData = () => {
    let query = generateQuery(tableState, fieldsType);

    setLoading(true);

    Request.alerts
      .get(query)
      .then((data) => {
        if (!data.rows.length && tableState.page > 1) {
          return setTableState({ ...tableState, page: tableState.page - 1 });
        }
        setLoading(false);
        setData(
          data.rows.map((item, index) => ({
            index: (tableState.page - 1) * tableState.limit + index + 1,
            ...item,
          }))
        );
        setTotal(data.total);
      })
      .catch(({ message }) => {
        setLoading(false);
      });
  };

  const deleteData = ({ id }) => {
    Request.alerts
      .delete({ id })
      .then(() => {
        setTableState((prev) => {
          return { ...prev };
        });
      })
      .catch(({ message }) => {
        notification.error({ message });
      });
  };

  const showDrawer = (selected = null) => {
    setSelected(selected);
    setVisibleDrawer(true);
  };

  const hideDrawer = (changed) => {
    setVisibleDrawer(false);
    if (changed) {
      setTableState((prev) => {
        return { ...prev };
      });
    }
  };

  const tableOnChange = (pagination, filters, sorter) => {
    let { order, field } = sorter;

    setTableState({
      filteredInfo: filters,
      sortedInfo: { order, field },
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  useEffect(() => {
    let _columns = [...columns];
    let { filteredInfo, sortedInfo } = tableState;

    _columns.map((column) => {
      column.filteredValue = filteredInfo[column.key] || null;
      column.sortOrder = sortedInfo.field === column.key && sortedInfo.order;
    });

    setColumns(_columns);

    const timeout = setTimeout(() => {
      getData();
    }, 500);
    return () => clearTimeout(timeout);
  }, [tableState]);

  useEffect(() => {
    if (!visibleDrawer) {
      setSelected(null);
    }
  }, [visibleDrawer]);

  return (
    <div>
      <TitlePage title="Alerts" />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexWrap: "wrap",
          gap: 8,
          marginBottom: 20,
        }}
      >
        <TableAddButton onClick={() => showDrawer()} />
      </div>

      <Table
        loading={loading}
        rowKey="id"
        columns={columns.filter((column) => column.visible)}
        dataSource={data}
        scroll={{
          x: "max-content",
          y: null,
        }}
        onChange={tableOnChange}
        size="small"
        pagination={{
          position: ["bottomCenter"],
          current: tableState.page,
          total: total,
          pageSize: tableState.limit,
          showSizeChanger: true,
        }}
        bordered
      />

      <AlertsDrawer
        visible={visibleDrawer}
        onClose={hideDrawer}
        selectedRow={selected}
      />
    </div>
  );
}
