import React, { useState, useEffect } from "react";
import { Pie } from "@ant-design/plots";
import { Card, Empty } from "antd";
import Request from "../../../Requests";

function AdminsActionsStatistics() {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const body = {
      query: JSON.stringify({
        page: 1,
        limit: 100,
      }),
    };
    Request.provider_statistics
      .get({ body })
      .then((data) => {
        const formattedData = data.map((item) => ({
          type: item.email,
          value: item.count,
        }));

        setChartData(formattedData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error.message);
        setLoading(false);
      });
  };

  const config = {
    appendPadding: 10,
    data: chartData,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    label: false,
    interactions: [
      {
        type: "element-active",
      },
    ],
    style: {
      minHeight: 250,
    },
  };

  if (!chartData) return <div></div>;

  return (
    <Card title="Resellers created by admins">
      {chartData.length > 0 ? <Pie {...config} /> : <Empty />}
    </Card>
  );
}

export default AdminsActionsStatistics;
