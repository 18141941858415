import { useState, useEffect } from "react";
import { Card } from "antd";
import { Chart } from "react-google-charts";
import Request from "../../../Requests";
import CountryCard from "./CountryCard";

export default function CountryChart() {
  const [dataList, setDataList] = useState([]);

  const originalWarn = console.warn;

  console.warn = function (...args) {
    const arg = args && args[0];

    if (arg && arg.includes("Attempting to load version '51' of Google Charts"))
      return;

    originalWarn(...args);
  };

  const getGeologation = () => {
    Request.dashboard.provider_country_chart({}).then((data) => {
      data.sort((a, b) => b.count - a.count);
      setDataList(data);
    });
  };

  const geolocationData = [["Country", "Popularity"]];

  dataList.map((item) => {
    geolocationData.push([item.country, item.count]);
  });

  useEffect(() => {
    getGeologation();
  }, []);
  return (
    <div className="geo-section">
      <Card
        title="Resellers by country"
        style={{
          width:  "calc(100% - 400px)",
          height:"470px"
        }}
      >
        <Chart
          chartEvents={[
            {
              eventName: "select",
              callback: ({ chartWrapper }) => {
                const chart = chartWrapper.getChart();
                const selection = chart.getSelection();
                if (selection.length === 0) return;
                const region = geolocationData[selection[0].row + 1];
              },
            },
          ]}
          chartType="GeoChart"
          width="100%"
          height="370px"
          data={geolocationData}
          options={{
            colorAxis: {
              colors: ["#2c93f4", "#073e4a"],
            },
            datalessRegionColor: "#cfe3e7",
            defaultColor: "#f5f5f5",
            legend: "none",
          }}
        />
      </Card>
      <CountryCard dataList={dataList} />
    </div>
  );
}
