import React from "react";
import ProvidersAnalytics from "./providers-analytics/ProvidersAnalytics";
import { TitlePage } from "../../components/index";
import "./_dashboard.scss";
export default function DashboardPage() {
  return (
    <>
      <TitlePage title="Dashboard" />
      <ProvidersAnalytics />
    </>
  );
}
