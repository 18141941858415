import { useCallback, useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";

import { Form, Button, message, notification } from "antd";

import Request from "../../../Requests";

import { FileUpload } from "../../../components";

export default function AppBrandingsDrawer({ theProviderId }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const logo = useRef(null);
  const backgroundImg = useRef(null);

  const [selectedRow, setSelectedRow] = useState(null);
  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");

  const onFinish = (values) => {
    let method = "put";
    const formData = new FormData();
    setLoading(true);
    if (backgroundImg.current) {
      formData.append("background_image", backgroundImg.current);
    }
    if (logo.current) {
      formData.append("logo", logo.current);
    }
    if (primaryColor) {
      formData.append("primary_color", primaryColor);
    }
    if (secondaryColor) {
      formData.append("secondary_color", secondaryColor);
    }
    if (selectedRow) {
      formData.append("id", selectedRow.id);
    }
    Request.app_brandings[method](formData)
      .then((res) => {
        setLoading(false);
        message.success("Success");
      })
      .catch(({ message }) => {
        setLoading(false);
        notification.error({ message });
      });
  };

  useEffect(() => {
    if (theProviderId) {
      Request.app_brandings
        .get({
          query: JSON.stringify({ filter: { provider_id: theProviderId } }),
        })
        .then((res) => {
          if (res) {
            setSelectedRow(res?.rows[0]);
            setPrimaryColor(res?.rows[0]?.primary_color);
            setSecondaryColor(res?.rows[0]?.secondary_color);
          }
        })
        .catch(({ message }) => {});
    }
  }, [theProviderId]);

  const onChangeCompletePrimary = useCallback(
    (color) => {
      setPrimaryColor(color?.hex);
    },
    [primaryColor]
  );

  const onChangeCompleteSecondary = useCallback(
    (color) => {
      setSecondaryColor(color?.hex);
    },
    [secondaryColor]
  );

  const getNewPathFile = (url, name, file) => {
    if (name == "logo") {
      setSelectedRow({ ...selectedRow, logo: url });
      logo.current = file;
    }

    if (name == "background_image") {
      setSelectedRow({ ...selectedRow, background_image: url });
      backgroundImg.current = file;
    }
  };
  return (
    <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
      <div className="app-branding-form">
        <div>
          <Form.Item label="Logo" name="logo">
            <FileUpload
              path={selectedRow?.logo}
              getNewPath={getNewPathFile}
              name="logo"
            />
          </Form.Item>

          {/* background change */}
          <Form.Item label="Background" name="background_image">
            <FileUpload
              path={selectedRow?.background_image}
              getNewPath={getNewPathFile}
              name="background_image"
            />
          </Form.Item>
        </div>

        <div className="right">
          <Form.Item label="Primary color">
            <SketchPicker
              color={primaryColor}
              onChangeComplete={onChangeCompletePrimary}
            />
          </Form.Item>

          <Form.Item label="Secondary color">
            <SketchPicker
              color={secondaryColor}
              onChangeComplete={onChangeCompleteSecondary}
            />
          </Form.Item>
        </div>
      </div>

      <Form.Item>
        <Button type="primary" onClick={() => form.submit()} loading={loading}>
          Update
        </Button>
      </Form.Item>
    </Form>
  );
}
