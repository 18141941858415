import { Drawer, Tabs, Form } from "antd";
import { useState, useEffect, useRef } from "react";

import Request from "../../Requests";

import ResellerInfo from "./components/ResellerInfo";
import SubResellers from "./components/SubResellers";
import DnsInfo from "./components/DnsInfo";
import AvailablePlatforms from "./components/AvailablePlatforms";
import AppBrandingsDrawer from "./components/AppBrandingsDrawer";
import CreditTransaction from "./components/CreditTransaction";
import NewBillingHistoryPage from "./components/BillingHistory";
import DevicesTab from "./components/DevicesTab";

export default function ResellerInfoDrawer({
  open,
  onClose,
  resellerId,
  setGetResellers = () => {},
}) {
  const [dataTab, setDataTab] = useState([]);

  const [tabKey, setTabKey] = useState("Info");

  const [providerInfo, setProviderInfo] = useState(null);

  const [dnsList, setDnsList] = useState([]);

  const [partnerProviderOs, setPartnerProviderOs] = useState([]);

  const [formResellerInfo] = Form.useForm();

  const [deactivatedAllDevices, setDeactivatedAllDevices] = useState(false);

  const theProviderId = useRef(resellerId);

  useEffect(() => {
    if (resellerId) {
      getProviderInfo();
      getDataPartnerProviderOs();
    }
  }, [resellerId]);

  useEffect(() => {
    if (open) {
      theProviderId.current = resellerId;
    } else {
      formResellerInfo.resetFields();
      setTabKey("Info");
      setProviderInfo(null);
      setDnsList([]);
      setPartnerProviderOs([]);
      theProviderId.current = null;
      setDeactivatedAllDevices(false);
    }
  }, [open, resellerId, theProviderId.current]);

  useEffect(() => {
    if (providerInfo) {
      if (
        providerInfo?.is_partner ||
        (providerInfo?.parent_id && !providerInfo?.is_partner)
      ) {
        data?.splice(1, 0, {
          key: "Sub resellers",
          label: "Sub resellers",
          children: <SubResellers theProviderId={theProviderId.current} />,
        });
      }

      data?.splice(4, 0, {
        key: "DNS",
        label: "DNS",
        children: <DnsInfo data={dnsList} />,
      });

      // if (partnerProviderOs?.length > 0) {
      data.push({
        key: "Available platforms",
        label: "Available platforms",
        children: (
          <AvailablePlatforms
            getData={getDataPartnerProviderOs}
            partnerProviderOs={partnerProviderOs && partnerProviderOs}
          />
        ),
      });
      setDataTab(data);
      // }
      setDataTab(data);

      setGetResellers(deactivatedAllDevices);
    }
  }, [
    providerInfo,
    partnerProviderOs?.length,
    dnsList?.length,
    deactivatedAllDevices,
  ]);

  const onChange = (key) => {
    setTabKey(key);
  };

  const onTabClick = (key) => {
    setTabKey(key);
  };

  const getProviderInfo = () => {
    Request.provider
      .provider_info({ id: resellerId })
      .then((data) => {
        setProviderInfo(data);
        // if (data?.is_partner) {
        // getDataPartnerProviderOs();
        // }
        if (data?.provider_servers?.length > 0) {
          setDnsList(data.provider_servers);
        }
      })
      .catch(({ message }) => {});
  };

  const getDataPartnerProviderOs = () => {
    Request.partner_provider_os
      .get({ query: JSON.stringify({ pagination: 0 }) })
      .then((data) => {
        const filtered = data?.filter((item) => {
          return item?.provider_id == resellerId;
        });
        if (filtered?.length > 0) {
          setPartnerProviderOs(filtered);
        } else {
          setPartnerProviderOs([]);
        }
      })
      .catch((err) => {});
  };

  const data = [
    {
      key: "Info",
      label: "Info",
      children: (
        <ResellerInfo
          theProviderId={theProviderId?.current}
          providerInfo={providerInfo}
          getProviderInfo={getProviderInfo}
          form={formResellerInfo}
          setDeactivatedAllDevices={setDeactivatedAllDevices}
          tabKey={tabKey}
          setGetResellers={setGetResellers}
        />
      ),
    },
    {
      key: "Devices",
      label: "Devices",
      children: (
        <DevicesTab
          theProviderId={theProviderId?.current}
          deactivatedAllDevices={deactivatedAllDevices}
          tabKey={tabKey}
        />
      ),
    },
    {
      key: "App brandings",
      label: "App brandings",
      children: <AppBrandingsDrawer theProviderId={theProviderId?.current} />,
    },
    {
      key: "Credit Transaction",
      label: "Credit Transaction",
      children: <CreditTransaction theProviderId={theProviderId?.current} />,
    },
    {
      key: "Billing history",
      label: "Billing history",
      children: (
        <NewBillingHistoryPage theProviderId={theProviderId?.current} />
      ),
    },
  ];

  return (
    <Drawer
      title={`Reseller: ${providerInfo?.brand_name} `}
      open={open}
      onClose={onClose}
      size="large"
      className="info-drawer"
    >
      <Tabs
        activeKey={tabKey}
        animated={true}
        type="card"
        onChange={onChange}
        onTabClick={onTabClick}
        items={dataTab}
      />
    </Drawer>
  );
}
