import React from "react";
import { Upload, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

export default function UploadComponent({
  fileUrl,
  setFileUrl,
  acceptType = "image/*",
}) {
  const getBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  };

  const beforeUpload = (file) => {
    return new Promise((resolve, reject) => {
      const isPhoto = file.type.startsWith("image/");
      const isVideo = file.type.startsWith("video/");

      if (isPhoto) {
        const isLt50M = file.size / 1024 / 1024 < 50;
        if (!isLt50M) {
          message.error("File must be smaller than 50MB!");
          return false;
        }

        const img = new Image();
        img.onload = () => {
          // if (img.width !== 1300 || img.height !== 900) {
          //   message.error("Photo dimensions must be 1300 x 900 pixels!");
          //   reject(false);
          // } else {
          resolve(true);
          // }
        };
        img.onerror = () => {
          message.error("Invalid image file!");
          reject(false);
        };
        img.src = URL.createObjectURL(file);
      } else if (isVideo) {
        const isMp4 = file.type === "video/mp4";
        const isLt30M = file.size / 1024 / 1024 < 30;

        if (!isMp4) {
          message.error("Video must be in MP4 format!");
          return reject(false);
        }

        if (!isLt30M) {
          message.error("Video must be smaller than 30 MB!");
          return reject(false);
        }

        const video = document.createElement("video");
        video.preload = "metadata";

        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src);
          // if (video.videoWidth !== 1300 || video.videoHeight !== 900) {
          //   message.error("Video dimensions must be 1300 x 900 pixels!");
          //   reject(false);
          // } else {
          resolve(true);
          // }
        };

        video.onerror = () => {
          window.URL.revokeObjectURL(video.src);
          message.error("Invalid video file!");
          reject(false);
        };

        video.src = URL.createObjectURL(file);
      } else {
        message.error("Unsupported file type!");
        reject(false);
      }
    });
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setFileUrl({
          file: info.file.originFileObj,
          url: url,
        });
      });
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <Upload
      accept={acceptType}
      customRequest={({ file, onSuccess }) => {
        setTimeout(() => onSuccess("ok"), 0);
      }}
      maxCount={1}
      onChange={handleChange}
      beforeUpload={beforeUpload}
      onRemove={() =>
        setFileUrl({
          file: null,
          url: null,
        })
      }
      listType="picture-card"
      showUploadList={false}
      style={{ height: "200px" }}
    >
      {fileUrl ? (
        acceptType.startsWith("video") ? (
          <video src={fileUrl} controls style={{ width: "100%" }} />
        ) : (
          <img src={fileUrl} alt="Uploaded file" style={{ width: "100%" }} />
        )
      ) : (
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      )}

      {/* <p className="ant-upload-drag-icon">{<InboxOutlined />}</p>

      <p style={{ fontSize: "18px" }}>
        <b>Select file too upload</b>
      </p>
      <p className="ant-upload-hint">
        The dimensions of the file are <b>1300 x 900</b> pixels
      </p>
      {acceptType == "video/*" && (
        <p className="ant-upload-hint">
          Videos must be less ten <b>50 MB</b>
        </p>
      )}
      {acceptType == "image/*" && (
        <p className="ant-upload-hint">
          Images must be less than <b>50 MB </b> in size.
        </p>
      )} */}
    </Upload>
  );
}
