import { useState, useEffect } from "react";

import { Table } from "antd";
import { CheckOutlined, CloseOutlined, RightOutlined } from "@ant-design/icons";

import Request from "../../../Requests";

import { TableText, TableDateHHMMSS, CopyText } from "../../../components";

import ResellerInfoDrawer from "../ResellerInfoDrawer";

export default function SubResellers({ theProviderId }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [openResellerInfo, setOpenResellerInfo] = useState({
    open: false,
    resellerId: null,
  });

  useEffect(() => {
    if (theProviderId) {
      getData();
    }
  }, [theProviderId]);

  const columns = [
    {
      title: "#",
      fixed: "left",
      key: "row__index",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text, record, index) => <TableText text={text} />,
    },
    {
      title: "Surname",
      dataIndex: "surname",
      key: "surname",
      align: "center",
      render: (text, record, index) => <TableText text={text} />,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (text, record, index) =>
        text ? (
          <CopyText
            text={<TableText text={text} style={{ color: "#1890ff" }} />}
          />
        ) : (
          "N/A"
        ),
    },

    {
      title: "Brand name",
      dataIndex: "brand_name",
      key: "brand_name",
      align: "center",
      render: (text, record, index) => <TableText text={text} />,
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      align: "center",
      render: (text, record, index) => <TableText text={text} />,
    },

    {
      title: "Device count",
      dataIndex: "device_count",
      key: "device_count",
      align: "center",
      render: (text, record, index) => <TableText text={text} />,
    },

    {
      title: "Blocked",
      dataIndex: "blocked",
      key: "blocked",
      align: "center",
      render: (text, record, index) => (record.blocked ? "Yes" : "No"),
    },

    {
      title: "Approved",
      dataIndex: "approved",
      key: "approved",
      align: "center",
      render: (text, record, index) => (
        <TableText
          text={
            text ? (
              <span>
                <CheckOutlined style={{ color: "#2ec4b6" }} />
              </span>
            ) : (
              <CloseOutlined style={{ color: "#e63946" }} />
            )
          }
        />
      ),
    },
    {
      title: "Need buy",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record, index) => <TableText text={text} />,
    },

    {
      title: "App activation type",
      dataIndex: "app_activation_type",
      key: "app_activation_type",
      align: "center",
      render: (text, record, index) => <TableText text={text} />,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      align: "center",
      render: (text, record, index) =>
        text ? <TableText text={text} /> : "N/A",
    },
    {
      title: "Updated date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
    },
    {
      title: "createdAt",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
    },
    {
      title: "",
      key: "row__actions",
      fixed: "right",
      width: 60,
      align: "center",
      render: (text, record, index) => (
        <div
          onClick={() =>
            setOpenResellerInfo({ open: true, resellerId: record?.id })
          }
          className="on-row"
        >
          <RightOutlined />
        </div>
      ),
    },
  ];

  const getData = () => {
    setLoading(true);
    Request.sub_resellers({ id: theProviderId })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch(({ message }) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Table
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={data}
        scroll={{
          x: "max-content",
          y: null,
        }}
        bordered={true}
        size="small"
        pagination={false}
      />

      <ResellerInfoDrawer
        resellerId={openResellerInfo?.resellerId}
        open={openResellerInfo.open}
        onClose={() => setOpenResellerInfo({ open: false, resellerId: null })}
      />
    </>
  );
}
