import React, { useEffect, useState } from "react";
import Request from "../../Requests";

import { UserOutlined } from "@ant-design/icons";

export default function Profile() {
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    getProfile();
  }, []);
  const getProfile = () => {
    Request.profile()
      .then((data) => {
        setProfile(data);
      })
      .catch((err) => {});
  };
  return (
    <div className="profile">
      <div className="user-outlined">
        <UserOutlined />
      </div>
      <span className="profile-title">
        {profile
          ? profile.name && profile.surname
            ? `${profile.name} ${profile.surname}`
            : profile.email
          : ""}
      </span>
    </div>
  );
}
