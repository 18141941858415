import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

export default function TableAddButton({ onClick = () => {}, title = "Add", loading, disabled }) {
    return (
        <Button disabled={disabled} loading={loading} type="primary" icon={<PlusOutlined />} onClick={onClick}>
            {title}
        </Button>
    );
}
