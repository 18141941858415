import { Typography } from "antd";

export default function TableText({ text = "", style, onClick }) {
  return (
    <Typography.Text
      style={{
        maxWidth: "170px",
        ...style,
      }}
      ellipsis={{ tooltip: text }}
      onClick={onClick}
    >
      {text}
    </Typography.Text>
  );
}
