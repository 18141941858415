import React, { useState, useEffect } from "react";
import { Bar } from "@ant-design/plots";
import moment from "moment";
import { Card, Empty, DatePicker } from "antd";
import Request from "../../../Requests";

export default function Credits() {
  const [dataList, setDataList] = useState([]);
  const defaultDate = `${new Date().getFullYear()}-01-01`;
  const [selectedDate, setSelectedDate] = useState(defaultDate);

  useEffect(() => {
    getData();
  }, [selectedDate]);


  const onChangeDate = (date, dateString) => {  
    if(date){
      const selectedYear = dateString;

      const formattedString = `${selectedYear}-01-01`;
  
      setSelectedDate(formattedString);
    }else {
      setSelectedDate(new Date());
      return selectedDate;
    }
  };

  const getData = () => {
    const query = {
      date: selectedDate,
    };
    Request.credit_statistics(query)
      .then((data) => {
        setDataList(data);
      })
      .catch(() => {});
  };


  const config = {
    data: dataList?.reverse(),
    isStack: true,
    xField: "value",
    yField: "month",
    seriesField: "type",
    label: {
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
    style:{
      minHeight: 250
    }
  };
  return (
    <Card
      title="Credits"
      extra={
        <DatePicker
          onChange={onChangeDate}
          picker="year"
          value={moment(selectedDate)}
        />
      }
    >
      {dataList.length > 0 ? <Bar {...config} /> : <Empty />}
    </Card>
  );
}
