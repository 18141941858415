import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  DashboardOutlined,
  InboxOutlined,
  TrademarkOutlined,
  SyncOutlined,
  BellOutlined,
  ContainerOutlined,
  AppstoreAddOutlined,
  FundProjectionScreenOutlined,
  ExclamationCircleOutlined,
  LogoutOutlined,
  BugOutlined,
  SettingOutlined,
  TeamOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";

import { Layout, Menu, Modal } from "antd";

import Request from "../Requests";

import { API_VERSION } from "../configs/config";
import greyLogo from "../assets/grey-logo.png";

export default function MenuContent({ onClose, isMobile }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [current, setCurrent] = useState("");

  const [profile, setProfile] = useState(null);

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (profile) {
      if (
        location.pathname == "/dashboard/admins" &&
        !profile?.permission_level
      ) {
        navigate("*");
      } else {
        setCurrent(location.pathname, profile?.permission_level);
      }
    }
  }, [location.pathname, profile]);

  const getProfile = () => {
    Request.profile()
      .then((data) => {
        setProfile(data);
      })
      .catch((err) => {});
  };

  const onClickItem = (e) => {
    if (e.key !== "logout") {
      setCurrent(e.key);
      navigate(e.key);
    }
  };

  const logOut = () => {
    Modal.confirm({
      title: "Do you want to logout ?",
      icon: <ExclamationCircleOutlined />,
      okText: "Logout",
      cancelText: "Cancel",
      onOk: () => {
        localStorage.clear();
        navigate("/login");
      },
    });
  };

  return (
    <>
      <div className="sidebar-logo-section">
        <div className="sidebar-logo">
          <img src={greyLogo} alt="logo" />
        </div>
      </div>
      <div className="sidebar-profile">
        {isMobile && (
          <span className="profile-title">
            {profile
              ? profile.name && profile.surname
                ? `${profile.name} ${profile.surname}`
                : profile.email
              : ""}
          </span>
        )}
      </div>
      <div className="sidebar-menu">
        <Menu
          mode="inline"
          selectedKeys={current}
          onClick={onClickItem}
          items={[
            {
              icon: <DashboardOutlined />,
              key: "/dashboard/analytics",
              label: "Dashboard",
            },

            profile?.permission_level && {
              icon: <TeamOutlined />,
              key: "/dashboard/admins",
              label: "Admins",
            },

            {
              icon: <InboxOutlined />,
              key: "/dashboard/partner-packages",
              label: "Partner Packages",
            },
            {
              icon: <TrademarkOutlined />,
              key: "/dashboard/resellers",
              label: "Resellers",
            },
            {
              icon: <FundProjectionScreenOutlined />,
              key: "/dashboard/devices",
              label: "Devices",
            },
            {
              icon: <SyncOutlined />,
              key: "/dashboard/credit-transaction",
              label: "Credit transaction",
            },
            {
              icon: <BellOutlined />,
              key: "/dashboard/alerts",
              label: "Alerts",
            },
            {
              icon: <ContainerOutlined />,
              key: "/dashboard/news",
              label: "News",
            },
            {
              icon: <CreditCardOutlined />,
              key: "/dashboard/banner-ad",
              label: "Banner Ad",
            },
            {
              icon: <AppstoreAddOutlined />,
              key: "/dashboard/platforms",
              label: "Platforms",
            },
            {
              icon: <BugOutlined />,
              key: "/dashboard/player-error-logs",
              label: "Error logs",
            },
            {
              icon: <SettingOutlined />,
              key: "/dashboard/settings",
              label: "Settings",
            },
            {
              icon: <LogoutOutlined style={{ color: "#ea474c" }} />,
              key: "logout",
              label: (
                <div className="log-out" onClick={logOut}>
                  {" "}
                  Log out{" "}
                </div>
              ),
            },
          ]}
          onSelect={() => {
            if (onClose) onClose();
          }}
        />
      </div>

      <div className="version-sidebar">
        <div># Version: {API_VERSION}</div>
      </div>
    </>
  );
}
