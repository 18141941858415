import React from "react";
import { Table } from "antd";
import {
  TableText,
  TableDateHHMMSS,
  CopyText,
  TableBoolean,
} from "../../../components";

export default function DnsInfo({ data }) {
  const columns = [
    {
      title: "#",
      fixed: "left",
      key: "row__index",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text, record, index) => <TableText text={text} />,
    },
    {
      title: "Host",
      dataIndex: "host",
      key: "host",
      align: "center",
      render: (text, record, index) =>
        text ? (
          <CopyText
            text={<TableText text={text} style={{ color: "#1890ff" }} />}
          />
        ) : (
          "N/A"
        ),
    },
    {
      title: "Open for subresellers",
      dataIndex: "open_for_subresellers",
      key: "open_for_subresellers",
      align: "center",
      width: 190,
      sorter: true,
      render: (text, record, index) => <TableBoolean value={text} />,
    },

    {
      title: "Open for devices",
      dataIndex: "open_for_devices",
      key: "open_for_devices",
      align: "center",
      width: 170,
      sorter: true,
      render: (text, record, index) => <TableBoolean value={text} />,
    },
    {
      title: "Updated date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
    },
    {
      title: "createdAt",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
    },
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={data}
      scroll={{
        x: "max-content",
        y: null,
      }}
      bordered={true}
      size="small"
      pagination={false}
    />
  );
}
