import { Image } from "antd";
import errorImg from "../../assets/error-image.png";

export default function TableImage({ src = "", preview = false }) {
  return (
    <Image
      style={{ height: 50 }}
      src={src}
      onError={(error) => (error.target.src = errorImg)}
      preview={preview}
    />
  );
}
