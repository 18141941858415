import { Space, Button, Popconfirm } from "antd";

import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

export default function TableActions({
  onEdit,
  onDelete,
  record,
  showEdit = true,
  showDel = true,


}) {
  return (
    <Space>
      {showEdit && (
        <Button
          onClick={() => onEdit(record)}
          type="primary"
          icon={<EditOutlined />}
        ></Button>
      )}
      {showDel && (
        <Popconfirm
          title="Are you sure to delete ?"
          placement="topRight"
          onConfirm={() => onDelete(record)}
        >
          <Button type="danger" icon={<DeleteOutlined />}></Button>
        </Popconfirm>
      )}
    </Space>
  );
}
