import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import AdminsDrawer from "./AdminsDrawer.jsx";
import CreatingAdminsDrawer from "./CreatingAdminsDrawer.jsx";
import Request from "../../Requests";
import { TableText, TitlePage, TableDateHHMMSS } from "../../components";
import { TableBoolean } from "../../components";

export default function AdminsPage() {
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (!visibleDrawer) {
            setSelected(null);
        }
    }, [visibleDrawer]);

    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
            width: 70,
            align: "center",
            render: (text, record, index) => text,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "center",
            width: 230,
            render: (text, record, index) => <TableText text={text ? text : "Not Available"} />,
        },
        {
            title: "Surname",
            dataIndex: "surname",
            key: "surname",
            align: "center",
            width: 230,
            render: (text, record, index) => <TableText text={text ? text : "Not Available"} />,
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            align: "center",
            width: 230,
            render: (text, record, index) => <TableText text={text} />,
        },
        {
            title: "Permission level",
            dataIndex: "permission_level",
            key: "permission_level",
            align: "center",
            width: 230,
            render: (text, record, index) => <TableBoolean value={record.permission_level} />,
        },
        {
            title: "Updated date",
            dataIndex: "updatedAt",
            key: "updatedAt",
            align: "center",
            render: (text, record, index) => <TableDateHHMMSS date={text} />,
            sorter: true,
        },
        {
            title: "Created date",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            render: (text, record, index) => <TableDateHHMMSS date={text} />,
            sorter: true,
        },
        {
            title: "Action",
            key: "row__actions",
            fixed: "right",
            width: 100,
            align: "center",
            render: (text, record, index) => (
                <Button onClick={() => showDrawer(record)} type="primary" icon={<EditOutlined />} />
            ),
        },
    ];

    const getData = () => {
        setLoading(true);

        Request.admins
            .get()
            .then((data) => {
                setLoading(false);
                setData(data);
            })
            .catch(({ message }) => {
                setLoading(false);
            });
    };

    const showDrawer = (selected = null) => {
        setSelected(selected);
        setVisibleDrawer(true);
    };

    const hideDrawer = (changed) => {
        setVisibleDrawer(false);
        getData();
    };

    const showCreatingDrawer = () => {
        setVisibleDrawer(true);
    };

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TitlePage title="Admins" />
                <Button type="primary" onClick={showCreatingDrawer}>
                    Create New Admin
                </Button>
            </div>
            <Table
                loading={loading}
                rowKey="id"
                columns={columns}
                dataSource={data}
                scroll={{
                    x: "max-content",
                    y: null,
                }}
                size="small"
                pagination={false}
                bordered
            />
            {visibleDrawer ? (
                selected ? (
                    <AdminsDrawer
                        visible={visibleDrawer}
                        onClose={hideDrawer}
                        selectedRow={selected}
                    />
                ) : (
                    <CreatingAdminsDrawer visible={visibleDrawer} onClose={hideDrawer} />
                )
            ) : null}
        </div>
    );
}
