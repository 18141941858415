import { useState, useEffect, useRef } from "react";

import { Table, notification, Button, Space, Popconfirm } from "antd";

import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  StopOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import PackagesDrawer from "./PartnerPackagesDrawer";

import {
  TableAddButton,
  TableText,
  TitlePage,
  TableDateHHMMSS,
  RangePickerFilterDropdown,
} from "../../components";

import Request from "../../Requests";

import { getColumnSearchProps, getColumnDateProps } from "../../configs/config";

import { generateQuery } from "../../Utils";

import useHistorySettings from "../../hooks/useHistorySettings";

export default function PartnerPackagesPage() {
  const fieldsType = {
    id: "INTEGER",
    name: "STRING",
    device_count: "NUMBER",
    price: "NUMBER",
    unlimited: "BOOLEAN",
    updatedAt: "DATE",
    createdAt: "DATE",
  };

  const defaultTableState = {
    filteredInfo: {},
    sortedInfo: {},
    page: 1,
    limit: 20,
  };

  const searchInputRef = useRef(null);

  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const [data, setData] = useState([]);

  const [selected, setSelected] = useState(null);

  const [loading, setLoading] = useState(false);

  const [tableState, setTableState] = useHistorySettings(defaultTableState);

  const [total, setTotal] = useState(0);

  const [columns, setColumns] = useState([
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: 70,
      align: "center",
      render: (text, record, index) => text,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: 230,
      render: (text, record, index) => <TableText text={text} />,
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Device count",
      dataIndex: "device_count",
      key: "device_count",
      align: "center",
      width: 150,
      sorter: true,

      render: (text, record, index) => <TableText text={text} />,
    },
    {
      title: "Unlimited",
      dataIndex: "unlimited",
      key: "unlimited",
      align: "center",
      width: 100,
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      render: (text, record, index) => (
        <TableText
          text={
            text ? (
              <span>
                <CheckOutlined style={{ color: "#2ec4b6" }} />
              </span>
            ) : (
              <span>
                <CloseOutlined style={{ color: "#e63946" }} />
              </span>
            )
          }
        />
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "center",
      width: 150,
      sorter: true,
      render: (text, record, index) => <TableText text={text} />,
    },
    {
      title: "Updated date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      render: (text, record, index) => <TableDateHHMMSS date={text} />,
      sorter: true,
      filterDropdown: RangePickerFilterDropdown,
    },

    {
      title: "Created date",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text, record, index) => <TableDateHHMMSS date={text} />,
      sorter: true,
      filterDropdown: RangePickerFilterDropdown,
    },
    {
      title: "Action",
      key: "row__actions",
      fixed: "right",
      width: 100,
      align: "center",
      render: (text, record, index) => (
        <Space>
          {
            <Button
              onClick={() => showDrawer(record)}
              type="primary"
              icon={<EditOutlined />}
            ></Button>
          }
          {
            <Popconfirm
              title={`Are you sure to ${
                record.archived ? "unarchive" : "archive"
              } ?`}
              placement="topRight"
              onConfirm={() => onChangeArchived(record)}
            >
              <Button
                style={{
                  backgroundColor: record.archived ? "#52b788 " : "#fb6f92",
                  border: "none",
                }}
                type="danger"
                icon={
                  record.archived ? <CheckCircleOutlined /> : <StopOutlined />
                }
              ></Button>
            </Popconfirm>
          }
        </Space>
      ),
    },
  ]);

  const getData = () => {
    let query = generateQuery(tableState, fieldsType);

    setLoading(true);

    Request.partner_packages
      .get(query)
      .then((data) => {
        if (!data.rows.length && tableState.page > 1) {
          return setTableState({ ...tableState, page: tableState.page - 1 });
        }
        setLoading(false);
        setData(
          data.rows.map((item, index) => ({
            index: (tableState.page - 1) * tableState.limit + index + 1,
            ...item,
          }))
        );
        setTotal(data.total);
      })
      .catch(({ message }) => {
        setLoading(false);
      });
  };

  const onChangeArchived = (data) => {
    Request.partner_packages
      .put({ id: data.id, price: data.price, archived: !data.archived })
      .then(() => {
        setTableState((prev) => {
          return { ...prev };
        });
      })
      .catch(({ message }) => {
        notification.error({ message });
      });
  };

  const showDrawer = (selected = null) => {
    setSelected(selected);
    setVisibleDrawer(true);
  };

  const hideDrawer = (changed) => {
    setVisibleDrawer(false);
    if (changed) {
      setTableState((prev) => {
        return { ...prev };
      });
    }
  };

  const tableOnChange = (pagination, filters, sorter) => {
    let { order, field } = sorter;

    setTableState({
      filteredInfo: filters,
      sortedInfo: { order, field },
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  useEffect(() => {
    let _columns = [...columns];
    let { filteredInfo, sortedInfo } = tableState;

    _columns.map((column) => {
      column.filteredValue = filteredInfo[column.key] || null;
      column.sortOrder = sortedInfo.field === column.key && sortedInfo.order;
    });

    setColumns(_columns);
    setTimeout(() => {
      getData();
    }, 300);
  }, [tableState]);

  useEffect(() => {
    if (!visibleDrawer) {
      setSelected(null);
    }
  }, [visibleDrawer]);

  const rowClassName = (record, index) => {
    return record.archived && "danger-row";
  };

  return (
    <div>
      <TitlePage title="Partner Packages" />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 8,
          marginBottom: 20,
        }}
      >
        <TableAddButton onClick={() => showDrawer()} />
      </div>

      <Table
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={data}
        rowClassName={rowClassName}
        scroll={{
          x: "max-content",
          y: null,
        }}
        onChange={tableOnChange}
        size="small"
        pagination={{
          position: ["bottomCenter"],
          current: tableState.page,
          total: total,
          pageSize: tableState.limit,
          showSizeChanger: true,
        }}
        bordered
      />
      <PackagesDrawer
        visible={visibleDrawer}
        onClose={hideDrawer}
        selectedRow={selected}
      />
    </div>
  );
}
