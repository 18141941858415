const countries = [
  {
    name: "Afghanistan",
    iso_code: "af",
    icon: "af.png",
    country_code: "93",
    language_code: "ps",
    language: "pashto",
    iso_code3: "afg",
    continent: "Asia",
  },
  {
    name: "Albania",
    iso_code: "al",
    icon: "al.png",
    country_code: "355",
    language_code: "sq",
    language: "albanian",
    iso_code3: "alb",
    continent: "Europe",
  },
  {
    name: "Algeria",
    iso_code: "dz",
    icon: "dz.png",
    country_code: "213",
    language_code: "tzm",
    language: "tamazight (latin)",
    iso_code3: "dza",
    continent: "Africa",
  },
  {
    name: "American Samoa",
    iso_code: "as",
    icon: "as.png",
    country_code: "1684",
    iso_code3: "asm",
    continent: "Oceania",
  },
  {
    name: "Andorra",
    iso_code: "ad",
    icon: "ad.png",
    country_code: "376",
    iso_code3: "and",
    continent: "Europe",
  },
  {
    name: "Angola",
    iso_code: "ao",
    icon: "ao.png",
    country_code: "244",
    iso_code3: "ago",
    continent: "Africa",
  },
  {
    name: "Anguilla",
    iso_code: "ai",
    icon: "ai.png",
    country_code: "1264",
    iso_code3: "aia",
    continent: "North America",
  },
  {
    name: "Antigua and Barbuda",
    iso_code: "ag",
    icon: "ag.png",
    country_code: "1268",
    iso_code3: "atg",
    continent: "North America",
  },
  {
    name: "Argentina",
    iso_code: "ar",
    icon: "ar.png",
    country_code: "54",
    language_code: "es",
    language: "spanish",
    iso_code3: "arg",
    continent: "South America",
  },
  {
    name: "Armenia",
    iso_code: "am",
    icon: "am.png",
    country_code: "374",
    language_code: "hy",
    language: "armenian",
    iso_code3: "arm",
    continent: "Asia",
  },
  {
    name: "Aruba",
    iso_code: "aw",
    icon: "aw.png",
    country_code: "297",
    iso_code3: "abw",
    continent: "North America",
  },
  {
    name: "Australia",
    iso_code: "au",
    icon: "au.png",
    country_code: "61",
    language_code: "en",
    language: "english",
    iso_code3: "aus",
    continent: "Oceania",
  },
  {
    name: "Austria",
    iso_code: "at",
    icon: "at.png",
    country_code: "43",
    language_code: "de",
    language: "german",
    iso_code3: "aut",
    continent: "Europe",
  },
  {
    name: "Azerbaijan",
    iso_code: "az",
    icon: "az.png",
    country_code: "994",
    language_code: "az",
    language: "azeri (latin)",
    iso_code3: "aze",
    continent: "Asia",
  },
  {
    name: "Bahamas",
    iso_code: "bs",
    icon: "bs.png",
    country_code: "1242",
    iso_code3: "bhs",
    continent: "North America",
  },
  {
    name: "Bahrain",
    iso_code: "bh",
    icon: "bh.png",
    country_code: "973",
    language_code: "ar",
    language: "arabic",
    iso_code3: "bhr",
    continent: "Asia",
  },
  {
    name: "Bangladesh",
    iso_code: "bd",
    icon: "bd.png",
    country_code: "880",
    language_code: "bn",
    language: "bengali",
    iso_code3: "bgd",
    continent: "Asia",
  },
  {
    name: "Barbados",
    iso_code: "bb",
    icon: "bb.png",
    country_code: "1246",
    iso_code3: "brb",
    continent: "North America",
  },
  {
    name: "Belarus",
    iso_code: "by",
    icon: "by.png",
    country_code: "375",
    language_code: "be",
    language: "belarusian",
    iso_code3: "blr",
    continent: "Europe",
  },
  {
    name: "Belgium",
    iso_code: "be",
    icon: "be.png",
    country_code: "32",
    language_code: "fr",
    language: "french",
    iso_code3: "bel",
    continent: "Europe",
  },
  {
    name: "Belize",
    iso_code: "bz",
    icon: "bz.png",
    country_code: "501",
    language_code: "en",
    language: "english",
    iso_code3: "blz",
    continent: "North America",
  },
  {
    name: "Benin",
    iso_code: "bj",
    icon: "bj.png",
    country_code: "229",
    iso_code3: "ben",
    continent: "Africa",
  },
  {
    name: "Bermuda",
    iso_code: "bm",
    icon: "bm.png",
    country_code: "1441",
    iso_code3: "bmu",
    continent: "North America",
  },
  {
    name: "Bhutan",
    iso_code: "bt",
    icon: "bt.png",
    country_code: "975",
    iso_code3: "btn",
    continent: "Asia",
  },
  {
    name: "Bolivia",
    iso_code: "bo",
    icon: "bo.png",
    country_code: "591",
    language_code: "es",
    language: "spanish",
    iso_code3: "bol",
    continent: "South America",
  },
  {
    name: "Bosnia and Herzegovina",
    iso_code: "ba",
    icon: "ba.png",
    country_code: "387",
    language_code: "sr",
    language: "serbian (latin)",
    iso_code3: "bih",
    continent: "Europe",
  },
  {
    name: "Botswana",
    iso_code: "bw",
    icon: "bw.png",
    country_code: "267",
    iso_code3: "bwa",
    continent: "Africa",
  },
  {
    name: "Brazil",
    iso_code: "br",
    icon: "br.png",
    country_code: "55",
    language_code: "pt",
    language: "portuguese",
    iso_code3: "bra",
    continent: "South America",
  },
  {
    name: "British Indian Ocean Territory",
    iso_code: "io",
    icon: "io.png",
    country_code: "246",
    iso_code3: "iot",
    continent: "Africa",
  },
  {
    name: "British Virgin Islands",
    iso_code: "vg",
    icon: "vg.png",
    country_code: "1284",
    iso_code3: "vgb",
    continent: "North America",
  },
  {
    name: "Brunei",
    iso_code: "bn",
    icon: "bn.png",
    country_code: "673",
    language_code: "ms",
    language: "malay",
    iso_code3: "brn",
    continent: "Asia",
  },
  {
    name: "Bulgaria",
    iso_code: "bg",
    icon: "bg.png",
    country_code: "359",
    language_code: "bg",
    language: "bulgarian",
    iso_code3: "bgr",
    continent: "Europe",
  },
  {
    name: "Burkina Faso",
    iso_code: "bf",
    icon: "bf.png",
    country_code: "226",
    iso_code3: "bfa",
    continent: "Africa",
  },
  {
    name: "Burma-Myanmar",
    iso_code: "mm",
    icon: "mm.png",
    country_code: "95",
    iso_code3: "mmr",
    continent: "Asia",
  },
  {
    name: "Burundi",
    iso_code: "bi",
    icon: "bi.png",
    country_code: "257",
    iso_code3: "bdi",
    continent: "Africa",
  },
  {
    name: "Cambodia",
    iso_code: "kh",
    icon: "kh.png",
    country_code: "855",
    language_code: "km",
    language: "khmer",
    iso_code3: "khm",
    continent: "Asia",
  },
  {
    name: "Cameroon",
    iso_code: "cm",
    icon: "cm.png",
    country_code: "237",
    iso_code3: "cmr",
    continent: "Africa",
  },
  {
    name: "Canada",
    iso_code: "ca",
    icon: "ca.png",
    country_code: "1",
    language_code: "moh",
    language: "mohawk",
    iso_code3: "can",
    continent: "North America",
  },
  {
    name: "Cape Verde",
    iso_code: "cv",
    icon: "cv.png",
    country_code: "238",
    iso_code3: "cpv",
    continent: "Africa",
  },
  {
    name: "Cayman Islands",
    iso_code: "ky",
    icon: "ky.png",
    country_code: "1345",
    iso_code3: "cym",
    continent: "North America",
  },
  {
    name: "Central African Republic",
    iso_code: "cf",
    icon: "cf.png",
    country_code: "236",
    iso_code3: "caf",
    continent: "Africa",
  },
  {
    name: "Chad",
    iso_code: "td",
    icon: "td.png",
    country_code: "235",
    iso_code3: "tcd",
    continent: "Africa",
  },
  {
    name: "Chile",
    iso_code: "cl",
    icon: "cl.png",
    country_code: "56",
    language_code: "es",
    language: "spanish",
    iso_code3: "chl",
    continent: "South America",
  },
  {
    name: "China",
    iso_code: "cn",
    icon: "cn.png",
    country_code: "86",
    language_code: "ii",
    language: "yi",
    iso_code3: "chn",
    continent: "Asia",
  },
  {
    name: "Christmas Island",
    iso_code: "cx",
    icon: "cx.png",
    country_code: "6189",
    iso_code3: "cxr",
    continent: "Oceania",
  },
  {
    name: "Colombia",
    iso_code: "co",
    icon: "co.png",
    country_code: "57",
    language_code: "es",
    language: "spanish",
    iso_code3: "col",
    continent: "South America",
  },
  {
    name: "Comoros",
    iso_code: "km",
    icon: "km.png",
    country_code: "269",
    iso_code3: "com",
    continent: "Africa",
  },
  {
    name: "Congo",
    iso_code: "cg",
    icon: "cg.png",
    country_code: "242",
    iso_code3: "cog",
    continent: "Africa",
  },
  {
    name: "Cook Islands",
    iso_code: "ck",
    icon: "ck.png",
    country_code: "682",
    iso_code3: "cok",
    continent: "Oceania",
  },
  {
    name: "Costa Rica",
    iso_code: "cr",
    icon: "cr.png",
    country_code: "506",
    language_code: "es",
    language: "spanish",
    iso_code3: "cri",
    continent: "North America",
  },
  {
    name: "Croatia",
    iso_code: "hr",
    icon: "hr.png",
    country_code: "385",
    language_code: "hr",
    language: "croatian",
    iso_code3: "hrv",
    continent: "Europe",
  },
  {
    name: "Cuba",
    iso_code: "cu",
    icon: "cu.png",
    country_code: "53",
    iso_code3: "cub",
    continent: "North America",
  },
  {
    name: "Cyprus",
    iso_code: "cy",
    icon: "cy.png",
    country_code: "357",
    iso_code3: "cyp",
    continent: "Asia",
  },
  {
    name: "Czech Republic",
    iso_code: "cz",
    icon: "cz.png",
    country_code: "420",
    language_code: "cs",
    language: "czech",
    iso_code3: "cze",
    continent: "Europe",
  },
  {
    name: "Denmark",
    iso_code: "dk",
    icon: "dk.png",
    country_code: "45",
    language_code: "da",
    language: "danish",
    iso_code3: "dnk",
    continent: "Europe",
  },
  {
    name: "Djibouti",
    iso_code: "dj",
    icon: "dj.png",
    country_code: "253",
    iso_code3: "dji",
    continent: "Africa",
  },
  {
    name: "Dominica",
    iso_code: "dm",
    icon: "dm.png",
    country_code: "1767",
    iso_code3: "dma",
    continent: "North America",
  },
  {
    name: "Dominican Republic",
    iso_code: "do",
    icon: "do.png",
    country_code: "1849",
    language_code: "es",
    language: "spanish",
    iso_code3: "dom",
    continent: "North America",
  },
  //   {
  //     name: "Dominican Republic",
  //     iso_code: "do",
  //     icon: "do.png",
  //     country_code: "1829",
  //     language_code: "es",
  //     language: "spanish",
  //     iso_code3: "dom",
  //     continent: "North America",
  //   },
  //   {
  //     name: "Dominican Republic",
  //     iso_code: "do",
  //     icon: "do.png",
  //     country_code: "1809",
  //     language_code: "es",
  //     language: "spanish",
  //     iso_code3: "dom",
  //     continent: "North America",
  //   },
  {
    name: "East Timor",
    iso_code: "tl",
    icon: "tl.png",
    country_code: "670",
    iso_code3: "tls",
    continent: "Asia",
  },
  {
    name: "Ecuador",
    iso_code: "ec",
    icon: "ec.png",
    country_code: "593",
    language_code: "es",
    language: "spanish",
    iso_code3: "ecu",
    continent: "South America",
  },
  {
    name: "Egypt",
    iso_code: "eg",
    icon: "eg.png",
    country_code: "20",
    language_code: "ar",
    language: "arabic",
    iso_code3: "egy",
    continent: "Africa",
  },
  {
    name: "El Salvador",
    iso_code: "sv",
    icon: "sv.png",
    country_code: "503",
    language_code: "es",
    language: "spanish",
    iso_code3: "slv",
    continent: "North America",
  },
  {
    name: "Equatorial Guinea",
    iso_code: "gq",
    icon: "gq.png",
    country_code: "240",
    iso_code3: "gnq",
    continent: "Africa",
  },
  {
    name: "Eritrea",
    iso_code: "er",
    icon: "er.png",
    country_code: "291",
    iso_code3: "eri",
    continent: "Africa",
  },
  {
    name: "Estonia",
    iso_code: "ee",
    icon: "ee.png",
    country_code: "372",
    language_code: "et",
    language: "estonian",
    iso_code3: "est",
    continent: "Europe",
  },
  {
    name: "Ethiopia",
    iso_code: "et",
    icon: "et.png",
    country_code: "251",
    language_code: "am",
    language: "amharic",
    iso_code3: "eth",
    continent: "Africa",
  },
  {
    name: "Faroe Islands",
    iso_code: "fo",
    icon: "fo.png",
    country_code: "298",
    language_code: "fo",
    language: "faroese",
    iso_code3: "fro",
    continent: "Europe",
  },
  {
    name: "Fiji",
    iso_code: "fj",
    icon: "fj.png",
    country_code: "679",
    iso_code3: "fji",
  },
  {
    name: "Finland",
    iso_code: "fi",
    icon: "fi.png",
    country_code: "358",
    language_code: "sv",
    language: "swedish",
    iso_code3: "fin",
    continent: "Europe",
  },
  {
    name: "France",
    iso_code: "fr",
    icon: "fr.png",
    country_code: "33",
    language_code: "oc",
    language: "occitan",
    iso_code3: "fra",
    continent: "Europe",
  },
  {
    name: "French Guiana",
    iso_code: "gf",
    icon: "gf.png",
    country_code: "594",
    iso_code3: "guf",
    continent: "South America",
  },
  {
    name: "French Polynesia",
    iso_code: "pf",
    icon: "pf.png",
    country_code: "689",
    iso_code3: "pyf",
    continent: "Oceania",
  },
  {
    name: "Gabon",
    iso_code: "ga",
    icon: "ga.png",
    country_code: "241",
    iso_code3: "gab",
    continent: "Africa",
  },
  {
    name: "Gambia",
    iso_code: "gm",
    icon: "gm.png",
    country_code: "220",
    iso_code3: "gmb",
    continent: "Africa",
  },
  {
    name: "Georgia",
    iso_code: "ge",
    icon: "ge.png",
    country_code: "995",
    language_code: "ka",
    language: "georgian",
    iso_code3: "geo",
    continent: "Asia",
  },
  {
    name: "Germany",
    iso_code: "de",
    icon: "de.png",
    country_code: "49",
    language_code: "hsb",
    language: "upper sorbian",
    iso_code3: "deu",
    continent: "Europe",
  },
  {
    name: "Ghana",
    iso_code: "gh",
    icon: "gh.png",
    country_code: "233",
    iso_code3: "gha",
    continent: "Africa",
  },
  {
    name: "Gibraltar",
    iso_code: "gi",
    icon: "gi.png",
    country_code: "350",
    iso_code3: "gib",
    continent: "Europe",
  },
  {
    name: "Greece",
    iso_code: "gr",
    icon: "gr.png",
    country_code: "30",
    language_code: "el",
    language: "greek",
    iso_code3: "grc",
    continent: "Europe",
  },
  {
    name: "Greenland",
    iso_code: "gl",
    icon: "gl.png",
    country_code: "299",
    language_code: "kl",
    language: "greenlandic",
    iso_code3: "grl",
    continent: "North America",
  },
  {
    name: "Grenada",
    iso_code: "gd",
    icon: "gd.png",
    country_code: "1473",
    iso_code3: "grd",
    continent: "North America",
  },
  {
    name: "Guadeloupe",
    iso_code: "gp",
    icon: "gp.png",
    country_code: "590",
    iso_code3: "glp",
    continent: "North America",
  },
  {
    name: "Guam",
    iso_code: "gu",
    icon: "gu.png",
    country_code: "1671",
    iso_code3: "gum",
    continent: "Oceania",
  },
  {
    name: "Guatemala",
    iso_code: "gt",
    icon: "gt.png",
    country_code: "502",
    language_code: "es",
    language: "spanish",
    iso_code3: "gtm",
    continent: "North America",
  },
  {
    name: "Guinea",
    iso_code: "gn",
    icon: "gn.png",
    country_code: "224",
    iso_code3: "gin",
    continent: "Africa",
  },
  {
    name: "Guinea-Bissau",
    iso_code: "gw",
    icon: "gw.png",
    country_code: "245",
    iso_code3: "gnb",
    continent: "Africa",
  },
  {
    name: "Guyana",
    iso_code: "gy",
    icon: "gy.png",
    country_code: "592",
    iso_code3: "guy",
    continent: "South America",
  },
  {
    name: "Haiti",
    iso_code: "ht",
    icon: "ht.png",
    country_code: "509",
    iso_code3: "hti",
    continent: "North America",
  },
  {
    name: "Honduras",
    iso_code: "hn",
    icon: "hn.png",
    country_code: "504",
    language_code: "es",
    language: "spanish",
    iso_code3: "hnd",
    continent: "North America",
  },
  {
    name: "Hong Kong",
    iso_code: "hk",
    icon: "hk.png",
    country_code: "852",
    language_code: "zh",
    language: "chinese (traditional) legacy",
    iso_code3: "hkg",
    continent: "Asia",
  },
  {
    name: "Hungary",
    iso_code: "hu",
    icon: "hu.png",
    country_code: "36",
    language_code: "hu",
    language: "hungarian",
    iso_code3: "hun",
    continent: "Europe",
  },
  {
    name: "Iceland",
    iso_code: "is",
    icon: "is.png",
    country_code: "354",
    language_code: "is",
    language: "icelandic",
    iso_code3: "isl",
    continent: "Europe",
  },
  {
    name: "India",
    iso_code: "in",
    icon: "in.png",
    country_code: "91",
    language_code: "te",
    language: "telugu",
    iso_code3: "ind",
    continent: "Asia",
  },
  {
    name: "Indonesia",
    iso_code: "id",
    icon: "id.png",
    country_code: "62",
    language_code: "id",
    language: "indonesian",
    iso_code3: "idn",
    continent: "Asia",
  },
  {
    name: "Iran",
    iso_code: "ir",
    icon: "ir.png",
    country_code: "98",
    language_code: "fa",
    language: "persian",
    iso_code3: "irn",
    continent: "Asia",
  },
  {
    name: "Iraq",
    iso_code: "iq",
    icon: "iq.png",
    country_code: "964",
    language_code: "ar",
    language: "arabic",
    iso_code3: "irq",
    continent: "Asia",
  },
  {
    name: "Ireland",
    iso_code: "ie",
    icon: "ie.png",
    country_code: "353",
    language_code: "ga",
    language: "irish",
    iso_code3: "irl",
    continent: "Europe",
  },
  {
    name: "Israel",
    iso_code: "il",
    icon: "il.png",
    country_code: "972",
    language_code: "he",
    language: "hebrew",
    iso_code3: "isr",
    continent: "Asia",
  },
  {
    name: "Italy",
    iso_code: "it",
    icon: "it.png",
    country_code: "39",
    language_code: "it",
    language: "italian",
    iso_code3: "ita",
    continent: "Europe",
  },
  {
    name: "Ivory Coast",
    iso_code: "ci",
    icon: "ci.png",
    country_code: "225",
    iso_code3: "civ",
    continent: "Africa",
  },
  {
    name: "Jamaica",
    iso_code: "jm",
    icon: "jm.png",
    country_code: "1876",
    language_code: "en",
    language: "english",
    iso_code3: "jam",
    continent: "North America",
  },
  {
    name: "Japan",
    iso_code: "jp",
    icon: "jp.png",
    country_code: "81",
    language_code: "ja",
    language: "japanese",
    iso_code3: "jpn",
    continent: "Asia",
  },
  {
    name: "Jordan",
    iso_code: "jo",
    icon: "jo.png",
    country_code: "962",
    language_code: "ar",
    language: "arabic",
    iso_code3: "jor",
    continent: "Asia",
  },
  {
    name: "Kazakhstan",
    iso_code: "kz",
    icon: "kz.png",
    country_code: "7",
    language_code: "kk",
    language: "kazakh",
    iso_code3: "kaz",
    continent: "Asia",
  },
  {
    name: "Kenya",
    iso_code: "ke",
    icon: "ke.png",
    country_code: "254",
    language_code: "sw",
    language: "kiswahili",
    iso_code3: "ken",
    continent: "Africa",
  },
  {
    name: "Kiribati",
    iso_code: "ki",
    icon: "ki.png",
    country_code: "686",
    iso_code3: "kir",
    continent: "Oceania",
  },
  {
    name: "Kuwait",
    iso_code: "kw",
    icon: "kw.png",
    country_code: "965",
    language_code: "ar",
    language: "arabic",
    iso_code3: "kwt",
    continent: "Asia",
  },
  {
    name: "Kyrgyzstan",
    iso_code: "kg",
    icon: "kg.png",
    country_code: "996",
    language_code: "ky",
    language: "kyrgyz",
    iso_code3: "kgz",
    continent: "Asia",
  },
  {
    name: "Laos",
    iso_code: "la",
    icon: "la.png",
    country_code: "856",
    language_code: "lo",
    language: "lao",
    iso_code3: "lao",
    continent: "Asia",
  },
  {
    name: "Latvia",
    iso_code: "lv",
    icon: "lv.png",
    country_code: "371",
    language_code: "lv",
    language: "latvian",
    iso_code3: "lva",
    continent: "Europe",
  },
  {
    name: "Lebanon",
    iso_code: "lb",
    icon: "lb.png",
    country_code: "961",
    language_code: "ar",
    language: "arabic",
    iso_code3: "lbn",
    continent: "Asia",
  },
  {
    name: "Lesotho",
    iso_code: "ls",
    icon: "ls.png",
    country_code: "266",
    iso_code3: "lso",
    continent: "Africa",
  },
  {
    name: "Liberia",
    iso_code: "lr",
    icon: "lr.png",
    country_code: "231",
    iso_code3: "lbr",
    continent: "Africa",
  },
  {
    name: "Libya",
    iso_code: "ly",
    icon: "ly.png",
    country_code: "218",
    language_code: "ar",
    language: "arabic",
    iso_code3: "lby",
    continent: "Africa",
  },
  {
    name: "Liechtenstein",
    iso_code: "li",
    icon: "li.png",
    country_code: "423",
    language_code: "de",
    language: "german",
    iso_code3: "lie",
    continent: "Europe",
  },
  {
    name: "Lithuania",
    iso_code: "lt",
    icon: "lt.png",
    country_code: "370",
    language_code: "lt",
    language: "lithuanian",
    iso_code3: "ltu",
    continent: "Europe",
  },
  {
    name: "Luxembourg",
    iso_code: "lu",
    icon: "lu.png",
    country_code: "352",
    language_code: "lb",
    language: "luxembourgish",
    iso_code3: "lux",
    continent: "Europe",
  },
  {
    name: "Macau",
    iso_code: "mo",
    icon: "mo.png",
    country_code: "853",
    language_code: "zh",
    language: "chinese (traditional) legacy",
    iso_code3: "mac",
    continent: "Asia",
  },
  {
    name: "Macedonia",
    iso_code: "mk",
    icon: "mk.png",
    country_code: "389",
    language_code: "mk",
    language: "macedonian (fyrom)",
    iso_code3: "mkd",
    continent: "Europe",
  },
  {
    name: "Madagascar",
    iso_code: "mg",
    icon: "mg.png",
    country_code: "261",
    iso_code3: "mdg",
    continent: "Africa",
  },
  {
    name: "Malawi",
    iso_code: "mw",
    icon: "mw.png",
    country_code: "265",
    iso_code3: "mwi",
    continent: "Africa",
  },
  {
    name: "Malaysia",
    iso_code: "my",
    icon: "my.png",
    country_code: "60",
    language_code: "ms",
    language: "malay",
    iso_code3: "mys",
    continent: "Asia",
  },
  {
    name: "Maldives",
    iso_code: "mv",
    icon: "mv.png",
    country_code: "960",
    language_code: "dv",
    language: "divehi",
    iso_code3: "mdv",
    continent: "Asia",
  },
  {
    name: "Mali",
    iso_code: "ml",
    icon: "ml.png",
    country_code: "223",
    iso_code3: "mli",
    continent: "Africa",
  },
  {
    name: "Malta",
    iso_code: "mt",
    icon: "mt.png",
    country_code: "356",
    language_code: "mt",
    language: "maltese",
    iso_code3: "mlt",
    continent: "Europe",
  },
  {
    name: "Marshall Islands",
    iso_code: "mh",
    icon: "mh.png",
    country_code: "692",
    iso_code3: "mhl",
    continent: "Oceania",
  },
  {
    name: "Martinique",
    iso_code: "mq",
    icon: "mq.png",
    country_code: "596",
    iso_code3: "mtq",
    continent: "North America",
  },
  {
    name: "Mauritania",
    iso_code: "mr",
    icon: "mr.png",
    country_code: "222",
    iso_code3: "mrt",
    continent: "Africa",
  },
  {
    name: "Mauritius",
    iso_code: "mu",
    icon: "mu.png",
    country_code: "230",
    iso_code3: "mus",
    continent: "Africa",
  },
  {
    name: "Mayotte",
    iso_code: "yt",
    icon: "yt.png",
    country_code: "262",
    iso_code3: "myt",
    continent: "Africa",
  },
  {
    name: "Mexico",
    iso_code: "mx",
    icon: "mx.png",
    country_code: "52",
    language_code: "es",
    language: "spanish",
    iso_code3: "mex",
    continent: "North America",
  },
  {
    name: "Moldova",
    iso_code: "md",
    icon: "md.png",
    country_code: "373",
    iso_code3: "mda",
    continent: "Europe",
  },
  {
    name: "Monaco",
    iso_code: "mc",
    icon: "mc.png",
    country_code: "377",
    language_code: "fr",
    language: "french",
    iso_code3: "mco",
    continent: "Europe",
  },
  {
    name: "Mongolia",
    iso_code: "mn",
    icon: "mn.png",
    country_code: "976",
    language_code: "mn",
    language: "mongolian (cyrillic)",
    iso_code3: "mng",
    continent: "Asia",
  },
  {
    name: "Montenegro",
    iso_code: "me",
    icon: "me.png",
    country_code: "382",
    language_code: "sr",
    language: "serbian (latin)",
    iso_code3: "mne",
    continent: "Europe",
  },
  {
    name: "Montserrat",
    iso_code: "ms",
    icon: "ms.png",
    country_code: "1664",
    iso_code3: "msr",
    continent: "North America",
  },
  {
    name: "Morocco",
    iso_code: "ma",
    icon: "ma.png",
    country_code: "212",
    language_code: "ar",
    language: "arabic",
    iso_code3: "mar",
    continent: "Africa",
  },
  {
    name: "Mozambique",
    iso_code: "mz",
    icon: "mz.png",
    country_code: "258",
    iso_code3: "moz",
    continent: "Africa",
  },
  {
    name: "Namibia",
    iso_code: "na",
    icon: "na.png",
    country_code: "264",
    iso_code3: "nam",
    continent: "Africa",
  },
  {
    name: "Nauru",
    iso_code: "nr",
    icon: "nr.png",
    country_code: "674",
    iso_code3: "nru",
    continent: "Oceania",
  },
  {
    name: "Nepal",
    iso_code: "np",
    icon: "np.png",
    country_code: "977",
    language_code: "ne",
    language: "nepali",
    iso_code3: "npl",
    continent: "Asia",
  },
  {
    name: "Netherlands",
    iso_code: "nl",
    icon: "nl.png",
    country_code: "31",
    language_code: "fy",
    language: "frisian",
    iso_code3: "nld",
    continent: "Europe",
  },
  {
    name: "Curaçao",
    iso_code: "cw",
    icon: "cw.png",
    country_code: "599",
    iso_code3: "cuw",
    continent: "South America",
  },
  {
    name: "New Caledonia",
    iso_code: "nc",
    icon: "nc.png",
    country_code: "687",
    iso_code3: "ncl",
    continent: "Oceania",
  },
  {
    name: "New Zealand",
    iso_code: "nz",
    icon: "nz.png",
    country_code: "64",
    language_code: "mi",
    language: "maori",
    iso_code3: "nzl",
    continent: "Oceania",
  },
  {
    name: "Nicaragua",
    iso_code: "ni",
    icon: "ni.png",
    country_code: "505",
    language_code: "es",
    language: "spanish",
    iso_code3: "nic",
    continent: "North America",
  },
  {
    name: "Niger",
    iso_code: "ne",
    icon: "ne.png",
    country_code: "227",
    iso_code3: "ner",
    continent: "Africa",
  },
  {
    name: "Nigeria",
    iso_code: "ng",
    icon: "ng.png",
    country_code: "234",
    language_code: "yo",
    language: "yoruba",
    iso_code3: "nga",
    continent: "Africa",
  },
  {
    name: "Niue",
    iso_code: "nu",
    icon: "nu.png",
    country_code: "683",
    iso_code3: "niu",
    continent: "Oceania",
  },
  {
    name: "Norfolk Island",
    iso_code: "nf",
    icon: "nf.png",
    country_code: "672",
    iso_code3: "nfk",
    continent: "Oceania",
  },
  {
    name: "Northern Mariana Islands",
    iso_code: "mp",
    icon: "mp.png",
    country_code: "1670",
    iso_code3: "mnp",
    continent: "Oceania",
  },
  {
    name: "North Korea",
    iso_code: "kp",
    icon: "kp.png",
    country_code: "850",
    iso_code3: "prk",
    continent: "Asia",
  },
  {
    name: "Norway",
    iso_code: "no",
    icon: "no.png",
    country_code: "47",
    language_code: "sma",
    language: "sami (southern)",
    iso_code3: "nor",
    continent: "Europe",
  },
  {
    name: "Oman",
    iso_code: "om",
    icon: "om.png",
    country_code: "968",
    language_code: "ar",
    language: "arabic",
    iso_code3: "omn",
    continent: "Asia",
  },
  {
    name: "Pakistan",
    iso_code: "pk",
    icon: "pk.png",
    country_code: "92",
    language_code: "ur",
    language: "urdu",
    iso_code3: "pak",
    continent: "Asia",
  },
  {
    name: "Palau",
    iso_code: "pw",
    icon: "pw.png",
    country_code: "680",
    iso_code3: "plw",
    continent: "Oceania",
  },
  {
    name: "Palestine",
    iso_code: "ps",
    icon: "ps.png",
    country_code: "970",
    iso_code3: "pse",
    continent: "Asia",
  },
  {
    name: "Panama",
    iso_code: "pa",
    icon: "pa.png",
    country_code: "507",
    language_code: "es",
    language: "spanish",
    iso_code3: "pan",
    continent: "North America",
  },
  {
    name: "Papua New Guinea",
    iso_code: "pg",
    icon: "pg.png",
    country_code: "675",
    iso_code3: "png",
    continent: "Oceania",
  },
  {
    name: "Paraguay",
    iso_code: "py",
    icon: "py.png",
    country_code: "595",
    language_code: "es",
    language: "spanish",
    iso_code3: "pry",
    continent: "South America",
  },
  {
    name: "Peru",
    iso_code: "pe",
    icon: "pe.png",
    country_code: "51",
    language_code: "es",
    language: "spanish",
    iso_code3: "per",
    continent: "South America",
  },
  {
    name: "Philippines",
    iso_code: "ph",
    icon: "ph.png",
    country_code: "63",
    language_code: "en",
    language: "english",
    iso_code3: "phl",
    continent: "Asia",
  },
  {
    name: "Pitcairn Islands",
    iso_code: "pn",
    icon: "pn.png",
    country_code: "870",
    iso_code3: "pcn",
  },
  {
    name: "Poland",
    iso_code: "pl",
    icon: "pl.png",
    country_code: "48",
    language_code: "pl",
    language: "polish",
    iso_code3: "pol",
    continent: "Europe",
  },
  {
    name: "Portugal",
    iso_code: "pt",
    icon: "pt.png",
    country_code: "351",
    language_code: "pt",
    language: "portuguese",
    iso_code3: "prt",
    continent: "Europe",
  },
  {
    name: "Puerto Rico",
    iso_code: "pr",
    icon: "pr.png",
    country_code: "1787",
    language_code: "es",
    language: "spanish",
    iso_code3: "pri",
    continent: "North America",
  },
  {
    name: "Qatar",
    iso_code: "qa",
    icon: "qa.png",
    country_code: "974",
    language_code: "ar",
    language: "arabic",
    iso_code3: "qat",
    continent: "Asia",
  },
  {
    name: "Réunion",
    iso_code: "re",
    icon: "re.png",
    country_code: "262",
    iso_code3: "reu",
    continent: "Africa",
  },
  {
    name: "Romania",
    iso_code: "ro",
    icon: "ro.png",
    country_code: "40",
    language_code: "ro",
    language: "romanian",
    iso_code3: "rou",
    continent: "Europe",
  },
  {
    name: "Russia",
    iso_code: "ru",
    icon: "ru.png",
    country_code: "7",
    language_code: "sah",
    language: "russian",
    iso_code3: "rus",
    continent: "Asia",
  },
  {
    name: "Rwanda",
    iso_code: "rw",
    icon: "rw.png",
    country_code: "250",
    language_code: "rw",
    language: "kinyarwanda",
    iso_code3: "rwa",
    continent: "Africa",
  },
  {
    name: "Saint Helena",
    iso_code: "sh",
    icon: "sh.png",
    country_code: "290",
    iso_code3: "shn",
    continent: "Africa",
  },
  {
    name: "Saint Kitts and Nevis",
    iso_code: "kn",
    icon: "kn.png",
    country_code: "1869",
    iso_code3: "kna",
    continent: "North America",
  },
  {
    name: "Saint Lucia",
    iso_code: "lc",
    icon: "lc.png",
    country_code: "1758",
    iso_code3: "lca",
    continent: "North America",
  },
  {
    name: "Saint Martin",
    iso_code: "mf",
    icon: "mf.png",
    country_code: "1599",
    iso_code3: "maf",
    continent: "Europe",
  },
  {
    name: "Saint Pierre and Miquelon",
    iso_code: "pm",
    icon: "pm.png",
    country_code: "508",
    iso_code3: "spm",
    continent: "North America",
  },
  {
    name: "Saint Vincent and the Grenadines",
    iso_code: "vc",
    icon: "vc.png",
    country_code: "1784",
    iso_code3: "vct",
    continent: "North America",
  },
  {
    name: "Samoa",
    iso_code: "ws",
    icon: "ws.png",
    country_code: "685",
    iso_code3: "wsm",
    continent: "Oceania",
  },
  {
    name: "San Marino",
    iso_code: "sm",
    icon: "sm.png",
    country_code: "378",
    iso_code3: "smr",
    continent: "Europe",
  },
  {
    name: "São Tomé and Príncipe",
    iso_code: "st",
    icon: "st.png",
    country_code: "239",
    iso_code3: "stp",
    continent: "Africa",
  },
  {
    name: "Saudi Arabia",
    iso_code: "sa",
    icon: "sa.png",
    country_code: "966",
    language_code: "ar",
    language: "arabic",
    iso_code3: "sau",
    continent: "Asia",
  },
  {
    name: "Senegal",
    iso_code: "sn",
    icon: "sn.png",
    country_code: "221",
    language_code: "wo",
    language: "wolof",
    iso_code3: "sen",
    continent: "Africa",
  },
  {
    name: "Serbia",
    iso_code: "rs",
    icon: "rs.png",
    country_code: "381",
    language_code: "sr",
    language: "serbian (latin)",
    iso_code3: "srb",
    continent: "Europe",
  },
  {
    name: "Seychelles",
    iso_code: "sc",
    icon: "sc.png",
    country_code: "248",
    iso_code3: "syc",
    continent: "Africa",
  },
  {
    name: "Falkland Islands",
    iso_code: "fk",
    icon: "fk.png",
    country_code: "500",
    iso_code3: "flk",
    continent: "South America",
  },
  {
    name: "Sierra Leone",
    iso_code: "sl",
    icon: "sl.png",
    country_code: "232",
    iso_code3: "sle",
    continent: "Africa",
  },
  {
    name: "Singapore",
    iso_code: "sg",
    icon: "sg.png",
    country_code: "65",
    language_code: "en",
    language: "english",
    iso_code3: "sgp",
    continent: "Asia",
  },
  {
    name: "Slovakia",
    iso_code: "sk",
    icon: "sk.png",
    country_code: "421",
    language_code: "sk",
    language: "slovak",
    iso_code3: "svk",
    continent: "Europe",
  },
  {
    name: "Slovenia",
    iso_code: "si",
    icon: "si.png",
    country_code: "386",
    language_code: "sl",
    language: "slovenian",
    iso_code3: "svn",
    continent: "Europe",
  },
  {
    name: "Solomon Islands",
    iso_code: "sb",
    icon: "sb.png",
    country_code: "677",
    iso_code3: "slb",
    continent: "Oceania",
  },
  {
    name: "Somalia",
    iso_code: "so",
    icon: "so.png",
    country_code: "252",
    iso_code3: "som",
    continent: "Africa",
  },
  {
    name: "South Africa",
    iso_code: "za",
    icon: "za.png",
    country_code: "27",
    language_code: "tn",
    language: "setswana",
    iso_code3: "zaf",
    continent: "Africa",
  },
  {
    name: "South Korea",
    iso_code: "kr",
    icon: "kr.png",
    country_code: "82",
    language_code: "ko",
    language: "korean",
    iso_code3: "kor",
    continent: "Asia",
  },
  {
    name: "South Sudan",
    iso_code: "ss",
    icon: "ss.png",
    country_code: "211",
    iso_code3: "ssd",
    continent: "Africa",
  },
  {
    name: "Spain",
    iso_code: "es",
    icon: "es.png",
    country_code: "34",
    language_code: "es",
    language: "spanish",
    iso_code3: "esp",
    continent: "Europe",
  },
  {
    name: "Sri Lanka",
    iso_code: "lk",
    icon: "lk.png",
    country_code: "94",
    language_code: "si",
    language: "sinhala",
    iso_code3: "lka",
    continent: "Asia",
  },
  {
    name: "Sudan",
    iso_code: "sd",
    icon: "sd.png",
    country_code: "249",
    iso_code3: "sdn",
    continent: "Africa",
  },
  {
    name: "Suriname",
    iso_code: "sr",
    icon: "sr.png",
    country_code: "597",
    iso_code3: "sur",
    continent: "South America",
  },
  {
    name: "Swaziland",
    iso_code: "sz",
    icon: "sz.png",
    country_code: "268",
    iso_code3: "swz",
    continent: "Africa",
  },
  {
    name: "Sweden",
    iso_code: "se",
    icon: "se.png",
    country_code: "46",
    language_code: "sv",
    language: "swedish",
    iso_code3: "swe",
    continent: "Europe",
  },
  {
    name: "Switzerland",
    iso_code: "ch",
    icon: "ch.png",
    country_code: "41",
    language_code: "rm",
    language: "romansh",
    iso_code3: "che",
    continent: "Europe",
  },
  {
    name: "Syria",
    iso_code: "sy",
    icon: "sy.png",
    country_code: "963",
    language_code: "syr",
    language: "syriac",
    iso_code3: "syr",
    continent: "Asia",
  },
  {
    name: "Taiwan",
    iso_code: "tw",
    icon: "tw.png",
    country_code: "886",
    language_code: "zh",
    language: "chinese (traditional) legacy",
    iso_code3: "twn",
    continent: "Asia",
  },
  {
    name: "Tajikistan",
    iso_code: "tj",
    icon: "tj.png",
    country_code: "992",
    language_code: "tg",
    language: "tajik (cyrillic)",
    iso_code3: "tjk",
    continent: "Asia",
  },
  {
    name: "Tanzania",
    iso_code: "tz",
    icon: "tz.png",
    country_code: "255",
    iso_code3: "tza",
    continent: "Africa",
  },
  {
    name: "Thailand",
    iso_code: "th",
    icon: "th.png",
    country_code: "66",
    language_code: "th",
    language: "thai",
    iso_code3: "tha",
    continent: "Asia",
  },
  {
    name: "Togo",
    iso_code: "tg",
    icon: "tg.png",
    country_code: "228",
    iso_code3: "tgo",
    continent: "Africa",
  },
  {
    name: "Tokelau",
    iso_code: "tk",
    icon: "tk.png",
    country_code: "690",
    iso_code3: "tkl",
    continent: "Oceania",
  },
  {
    name: "Tonga",
    iso_code: "to",
    icon: "to.png",
    country_code: "676",
    iso_code3: "ton",
    continent: "Oceania",
  },
  {
    name: "Trinidad and Tobago",
    iso_code: "tt",
    icon: "tt.png",
    country_code: "1868",
    language_code: "en",
    language: "english",
    iso_code3: "tto",
    continent: "North America",
  },
  {
    name: "Tunisia",
    iso_code: "tn",
    icon: "tn.png",
    country_code: "216",
    language_code: "ar",
    language: "arabic",
    iso_code3: "tun",
    continent: "Africa",
  },
  {
    name: "Turkey",
    iso_code: "tr",
    icon: "tr.png",
    country_code: "90",
    language_code: "tr",
    language: "turkish",
    iso_code3: "tur",
    continent: "Asia",
  },
  {
    name: "Turkmenistan",
    iso_code: "tm",
    icon: "tm.png",
    country_code: "993",
    language_code: "tk",
    language: "turkmen",
    iso_code3: "tkm",
    continent: "Asia",
  },
  {
    name: "Turks and Caicos Islands",
    iso_code: "tc",
    icon: "tc.png",
    country_code: "1649",
    iso_code3: "tca",
    continent: "North America",
  },
  {
    name: "Tuvalu",
    iso_code: "tv",
    icon: "tv.png",
    country_code: "688",
    iso_code3: "tuv",
    continent: "Oceania",
  },
  {
    name: "Uganda",
    iso_code: "ug",
    icon: "ug.png",
    country_code: "256",
    iso_code3: "uga",
    continent: "Africa",
  },
  {
    name: "United Kingdom",
    iso_code: "gb",
    icon: "gb.png",
    country_code: "44",
    language_code: "cy",
    language: "welsh",
    iso_code3: "gbr",
    continent: "Europe",
  },
  {
    name: "Ukraine",
    iso_code: "ua",
    icon: "ua.png",
    country_code: "380",
    language_code: "uk",
    language: "ukrainian",
    iso_code3: "ukr",
    continent: "Europe",
  },
  {
    name: "United Arab Emirates",
    iso_code: "ae",
    icon: "ae.png",
    country_code: "971",
    language_code: "ar",
    language: "arabic",
    iso_code3: "are",
    continent: "Asia",
  },
  {
    name: "Uruguay",
    iso_code: "uy",
    icon: "uy.png",
    country_code: "598",
    language_code: "es",
    language: "spanish",
    iso_code3: "ury",
    continent: "South America",
  },
  {
    name: "United States",
    iso_code: "us",
    icon: "us.png",
    country_code: "1",
    language_code: "en",
    language: "english",
    iso_code3: "usa",
    continent: "North America",
  },
  {
    name: "Uzbekistan",
    iso_code: "uz",
    icon: "uz.png",
    country_code: "998",
    language_code: "uz",
    language: "uzbek (latin)",
    iso_code3: "uzb",
    continent: "Asia",
  },
  {
    name: "Vanuatu",
    iso_code: "vu",
    icon: "vu.png",
    country_code: "678",
    iso_code3: "vut",
    continent: "Oceania",
  },
  {
    name: "Venezuela",
    iso_code: "ve",
    icon: "ve.png",
    country_code: "58",
    language_code: "es",
    language: "spanish",
    iso_code3: "ven",
    continent: "South America",
  },
  {
    name: "Vietnam",
    iso_code: "vn",
    icon: "vn.png",
    country_code: "84",
    language_code: "vi",
    language: "vietnamese",
    iso_code3: "vnm",
    continent: "Asia",
  },
  {
    name: "Virgin Islands",
    iso_code: "vi",
    icon: "vi.png",
    country_code: "1340",
    iso_code3: "vir",
    continent: "North America",
  },
  {
    name: "Wallis and Futuna",
    iso_code: "wf",
    icon: "wf.png",
    country_code: "681",
    iso_code3: "wlf",
    continent: "Oceania",
  },
  {
    name: "Yemen",
    iso_code: "ye",
    icon: "ye.png",
    country_code: "967",
    language_code: "ar",
    language: "arabic",
    iso_code3: "yem",
    continent: "Asia",
  },
  {
    name: "Zambia",
    iso_code: "zm",
    icon: "zm.png",
    country_code: "260",
    iso_code3: "zmb",
    continent: "Africa",
  },
  {
    name: "Zimbabwe",
    iso_code: "zw",
    icon: "zw.png",
    country_code: "263",
    language_code: "en",
    language: "english",
    iso_code3: "zwe",
    continent: "Africa",
  },
];

export default countries;
