import React, { useState } from "react";
import { Drawer, Form, Button, notification, Input } from "antd";
import Request from "../../Requests";

const defaultValues = {
    email: "",
    password: "",
    name: "",
    surname: "",
};

function CreatingAdminsDrawer({ visible, onClose }) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = (data) => {
        setLoading(true);
        Request.admins
            .post(data)
            .then(() => {
                setLoading(false);
                hideDrawer(true);
                notification.success({ message: "Admin created successfully" });
            })
            .catch(({ message }) => {
                setLoading(false);
                notification.error({ message });
            });
    };

    const hideDrawer = (changed) => {
        onClose(changed);
        form.resetFields();
    };

    return (
        <Drawer
            title="Create Admin"
            placement="right"
            onClose={() => hideDrawer(false)}
            open={visible}
            width={600}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
                initialValues={defaultValues}
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: "Please input the email!" }]}
                    style={{ marginBottom: 10 }}
                >
                    <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: "Please input the password!" }]}
                    style={{ marginBottom: 10 }}
                >
                    <Input.Password placeholder="Password" />
                </Form.Item>
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: "Please input the name!" }]}
                    style={{ marginBottom: 10 }}
                >
                    <Input placeholder="Name" />
                </Form.Item>
                <Form.Item
                    label="Surname"
                    name="surname"
                    rules={[{ required: true, message: "Please input the surname!" }]}
                    style={{ marginBottom: 10 }}
                >
                    <Input placeholder="Surname" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={() => form.submit()} loading={loading}>
                        Create
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
}

export default CreatingAdminsDrawer;
