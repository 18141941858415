import React, { useState, useEffect } from "react";
import { Form, Button, notification, Checkbox, Empty } from "antd";

import { createFieldsValue } from "../../../Utils";

import Request from "../../../Requests";

export default function AvailablePlatforms({ partnerProviderOs, getData }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const fields = {
    androidmobile: {
      type: "BOOLEAN",
      rules: [{ required: false, message: "" }],
    },
    androidtv: { type: "BOOLEAN", rules: [{ required: false, message: "" }] },
    fire: { type: "BOOLEAN", rules: [{ required: false, message: "" }] },
    ios: { type: "BOOLEAN", rules: [{ required: false, message: "" }] },
    roku: { type: "BOOLEAN", rules: [{ required: false, message: "" }] },
    tizen: { type: "BOOLEAN", rules: [{ required: false, message: "" }] },
    tv_os: { type: "BOOLEAN", rules: [{ required: false, message: "" }] },
    vidda: { type: "BOOLEAN", rules: [{ required: false, message: "" }] },
    vizio: { type: "BOOLEAN", rules: [{ required: false, message: "" }] },
    webos: { type: "BOOLEAN", rules: [{ required: false, message: "" }] },
    zessen: { type: "BOOLEAN", rules: [{ required: false, message: "" }] },
    foxxum: { type: "BOOLEAN", rules: [{ required: false, message: "" }] },
  };

  const onFinish = (values) => {
    setLoading(true);
    let method = "put";

    if (partnerProviderOs?.length > 0) {
      values.id = partnerProviderOs[0].id;
    }

    Request.partner_provider_os[method](values)
      .then((res) => {
        notification.success({ message: res });
        setLoading(false);
        getData();
      })
      .catch(({ message }) => {
        notification.error({ message: message });
        setLoading(false);
      });
  };

  useEffect(() => {
    if (partnerProviderOs?.length > 0) {
      form.setFieldsValue(createFieldsValue(partnerProviderOs[0], fields));
    }
  }, []);

  // console.log(partnerProviderOs);
  return partnerProviderOs?.length > 0 ? (
    <Form form={form} onFinish={onFinish} autoComplete="off">
      <div className="checkboxs-list">
        <Form.Item
          name="androidmobile"
          valuePropName="checked"
          rules={fields.androidmobile.rules}
        >
          <Checkbox>Android mobile</Checkbox>
        </Form.Item>
        <Form.Item
          name="androidtv"
          valuePropName="checked"
          rules={fields.androidtv.rules}
        >
          <Checkbox>Android tv</Checkbox>
        </Form.Item>

        <Form.Item
          name="fire"
          valuePropName="checked"
          rules={fields.fire.rules}
        >
          <Checkbox>Fire</Checkbox>
        </Form.Item>
        <Form.Item name="ios" valuePropName="checked" rules={fields.ios.rules}>
          <Checkbox>Ios</Checkbox>
        </Form.Item>
        <Form.Item
          name="roku"
          valuePropName="checked"
          rules={fields.roku.rules}
        >
          <Checkbox>Roku</Checkbox>
        </Form.Item>
        <Form.Item
          name="tizen"
          valuePropName="checked"
          rules={fields.tizen.rules}
        >
          <Checkbox>Tizen</Checkbox>
        </Form.Item>

        <Form.Item
          name="tv_os"
          valuePropName="checked"
          rules={fields.tv_os.rules}
        >
          <Checkbox>Tv os</Checkbox>
        </Form.Item>

        <Form.Item
          name="vidda"
          valuePropName="checked"
          rules={fields.vidda.rules}
        >
          <Checkbox>Vidda</Checkbox>
        </Form.Item>

        <Form.Item
          name="vizio"
          valuePropName="checked"
          rules={fields.vizio.rules}
        >
          <Checkbox>Vizio</Checkbox>
        </Form.Item>

        <Form.Item
          name="webos"
          valuePropName="checked"
          rules={fields.webos.rules}
        >
          <Checkbox>Webos</Checkbox>
        </Form.Item>

        <Form.Item
          name="zessen"
          valuePropName="checked"
          rules={fields.zessen.rules}
        >
          <Checkbox>Zessen</Checkbox>
        </Form.Item>

        <Form.Item
          name="foxxum"
          valuePropName="checked"
          rules={fields.foxxum.rules}
        >
          <Checkbox>Foxxum</Checkbox>
        </Form.Item>
      </div>

      <Form.Item>
        <Button loading={loading} type="primary" htmlType="submit">
          Update
        </Button>
      </Form.Item>
    </Form>
  ) : (
    <Empty />
  );
}
