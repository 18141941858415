import { Button, Popconfirm, Radio } from "antd";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { DownloadOutlined } from "@ant-design/icons";

export default function TableCSV({ dataForExport, onExport }) {
  const [value, setValue] = useState(0);

  const getChecked = (e) => {
    if (e.target.value == 1) {
      onExport("allData");
    }
    if (e.target.value == 2) {
      onExport("data");
    }
  };

  const confirm = () => {
    setValue(0);
  };

  return (
    <Popconfirm
      placement="top"
      title={
        <Radio.Group onChange={getChecked} value={value}>
          <Radio value={1}>Export all data</Radio>
          <br />
          <Radio value={2}>Export page data</Radio>
        </Radio.Group>
      }
      onConfirm={confirm}
      icon={<></>}
      okText={<CSVLink data={dataForExport}>Yes</CSVLink>}
      showCancel={false}
    >
      <Button
        style={{
          background: "#5b8c00",
          borderColor: "#5b8c00",
          color: "white",
        }}
        icon={<DownloadOutlined />}
      >
        Export CSV
      </Button>
    </Popconfirm>
  );
}
