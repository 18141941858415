import React, { useState, useEffect } from "react";
import { Drawer, Form, Select, Input, Button, message } from "antd";
import Request from "../../../Requests";

export default function DeviceDrawer({
  open,
  onClose,
  provider,
  selected,
  getDevice,
}) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const hostListNewOptions = provider?.provider_servers?.map((item) => ({
    label: item.name + `(${item?.host})`,
    value: item.id,
  }));

  const canUseFree =
    provider?.parent_id && provider?.status === "CAN_USE_FREE_PARTNER_CREDITS";

  if (open && provider) {
    if (!canUseFree) {
      hostListNewOptions.unshift({
        value: null,
        label: "Custom Playlist",
      });
    }
  }
  useEffect(() => {
    if (open) {
      if (selected) {
        convertUsernamePasswordOnPlaylist();
        form.setFields([{ name: "playlist", value: selected.playlist }]);
      }
    } else {
      setLoading(false);
    }
  }, [open, selected]);

  const onFinish = (values) => {
    setLoading(true);

    const body = {
      id: Number(selected?.id),
      provider_id: Number(selected?.provider_id),
    };

    if (values.server_id) {
      body.server_id = Number(values.server_id) || selected?.server_id;
    }

    if (values.playlist) {
      body.playlist = values.playlist;
    }

    if (values.username || values.password) {
      body.username = values.username?.trim();
      body.password = values.password?.trim();
    }

    Request.devices_edit(body)
      .then((res) => {
        setLoading(false);
        onClose();
        getDevice();
        message.success(res);
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
  };

  const returnSelectedServer = (serverId) => {
    const _selsectedServer = hostListNewOptions?.find(
      (el) => el.value == serverId
    );

    if (_selsectedServer) {
      return _selsectedServer;
    }

    return null;
  };

  const convertUsernamePasswordOnPlaylist = () => {
    let currentPlaylist = selected?.playlist?.split("=");
    if (!currentPlaylist) return;
    let username = currentPlaylist[1]?.substring(
      0,
      currentPlaylist[1]?.length - 9
    );
    let password = currentPlaylist[2];

    form.setFields([
      { name: "server_id", value: returnSelectedServer(selected?.server_id) },
      { name: "username", value: username },
      { name: "password", value: password },
    ]);
  };

  return (
    <Drawer title="Edit" open={open} onClose={onClose} width={500}>
      <Form
        name="edit-device"
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item shouldUpdate noStyle>
          {() => {
            const { server_id } = form.getFieldsValue();
            return (
              <>
                <Form.Item
                  label="Select DNS"
                  name="server_id"
                  rules={[
                    {
                      required: false,
                      message: "Select DNS",
                    },
                  ]}
                >
                  <Select
                    options={hostListNewOptions}
                    placeholder="Select DNS"
                  />
                </Form.Item>

                {!server_id || server_id?.label == "Custom Playlist" ? (
                  <Form.Item
                    label="Playlist URL"
                    name="playlist"
                    rules={[
                      {
                        required: false,
                        message: "Please input playlist URL",
                      },
                      {
                        type: "url",
                        message: "That's not a valid URL",
                      },
                    ]}
                  >
                    <Input placeholder="Playlist URL" type="url" />
                  </Form.Item>
                ) : (
                  <div>
                    <Form.Item
                      label="Username"
                      name="username"
                      rules={[
                        {
                          required: false,
                          message: "Please input your username",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: false,
                          message: "Please input your password",
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </div>
                )}
              </>
            );
          }}
        </Form.Item>

        <Form.Item style={{ marginTop: "20px" }}>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
