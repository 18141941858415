import { useState, useEffect, useRef } from "react";

import { Table, Image, Button } from "antd";

import {
  TableText,
  RangePickerFilterDropdown,
  TableDateHHMMSS,
  CopyText,
  ResellerInfoDrawer,
} from "../../components";

import Request from "../../Requests";

import { generateQuery } from "../../Utils";

import useColumns from "../../hooks/useColumns";
import useHistorySettings from "../../hooks/useHistorySettings";
import { getColumnSearchProps } from "../../configs/config";

import errorImg from "../../assets/error-image.png";

export default function MoviesPage() {
  const fieldsType = {
    provider_id: "INTEGER",
    name: "STRING",
    device_os: "STRING",
    device_key: "STRING",
    count: "INTEGER",
    media_id: "STRING",
    createdAt: "DATE",
    updatedAt: "DATE",
  };

  const defaultTableState = {
    filteredInfo: {},
    sortedInfo: {},
    page: 1,
    limit: 20,
  };

  const searchInputRef = useRef(null);

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [tableState, setTableState] = useHistorySettings(defaultTableState);

  const [total, setTotal] = useState(0);

  const [openResellerInfo, setOpenResellerInfo] = useState({
    open: false,
    resellerId: null,
  });

  const [columns, setColumns] = useState([
    {
      title: "#",
      fixed: "left",
      key: "row__index",
      render: (text, record, index) => record.index,
    },
    {
      title: "Device key",
      dataIndex: "device_key",
      key: "device_key",
      fixed: "left",
      render: (text, record, index) =>
        text ? (
          <CopyText
            text={<TableText text={text} style={{ color: "#1890ff" }} />}
          />
        ) : (
          "N/A"
        ),
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Provider ID",
      dataIndex: "provider_id",
      key: "provider_id",
      render: (text, record, index) =>
        record.provider_id ? (
          <Button
            type="ghost"
            onClick={() =>
              setOpenResellerInfo({
                open: true,
                resellerId: record?.provider_id,
              })
            }
          >
            {text}
          </Button>
        ) : (
          "N/A"
        ),
      align: "center",
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Poster",
      dataIndex: "icon",
      key: "icon",
      width: 75,
      render: (text, record) => {
        return (
          <Image
            src={record?.icon || errorImg}
            alt="icon"
            onError={(e) => (e.target.src = errorImg)}
            preview={record?.icon ? true : false}
            style={{
              width: "47px",
              height: "53px",
              objectFit: "cover",
            }}
          />
        );
      },
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) =>
        text ? (
          <CopyText
            text={<TableText text={text} style={{ color: "#1890ff" }} />}
          />
        ) : (
          "N/A"
        ),

      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Device OS",
      dataIndex: "device_os",
      key: "device_os",
      render: (text, record, index) => <TableText text={text} />,
      ...getColumnSearchProps(searchInputRef),
    },

    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (text, record, index) =>
        text ? (
          <CopyText
            text={<TableText text={text} style={{ color: "#1890ff" }} />}
          />
        ) : (
          "N/A"
        ),

      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (text, record, index) => <TableText text={text || "N/A"} />,
    },
    {
      title: "Error count",
      dataIndex: "count",
      key: "count",
      render: (text, record, index) => <TableText text={text} />,
      sorter: true,
    },
    {
      title: "Movie ID",
      dataIndex: "media_id",
      key: "media_id",
      render: (text, record, index) =>
        text ? (
          <CopyText
            text={<TableText text={text} style={{ color: "#1890ff" }} />}
          />
        ) : (
          "N/A"
        ),

      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Updated date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
      sorter: true,
      filterDropdown: RangePickerFilterDropdown,
    },
    {
      title: "Created date",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
      sorter: true,
      filterDropdown: RangePickerFilterDropdown,
    },
  ]);

  const [onChangeColumns] = useColumns(columns, setColumns, "movies");

  const getData = () => {
    setLoading(true);

    let query = generateQuery(tableState, fieldsType);

    let providerQuery = JSON.parse(query.query);

    providerQuery = {
      ...providerQuery,
      filter: { type: "movies" },
    };

    query.query = JSON.stringify(providerQuery);

    Request.player_error_logs
      .get(query)
      .then((data) => {
        if (!data.rows.length && tableState.page > 1) {
          return setTableState({ ...tableState, page: tableState.page - 1 });
        }

        setLoading(false);
        setData(
          data.rows.map((item, index) => ({
            index: (tableState.page - 1) * tableState.limit + index + 1,
            ...item,
          }))
        );
        setTotal(data.total);
      })
      .catch(({ message }) => {
        setLoading(false);
      });
  };
  const tableOnChange = (pagination, filters, sorter) => {
    let { order, field } = sorter;

    setTableState({
      filteredInfo: filters,
      sortedInfo: { order, field },
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };
  useEffect(() => {
    let _columns = [...columns];
    let { filteredInfo, sortedInfo } = tableState;

    _columns.map((column) => {
      column.filteredValue = filteredInfo[column.key] || null;
      column.sortOrder = sortedInfo.field === column.key && sortedInfo.order;
    });

    setColumns(_columns);

    const timeout = setTimeout(() => {
      getData();
    }, 500);
    return () => clearTimeout(timeout);
  }, [tableState]);
  return (
    <>
      <Table
        loading={loading}
        rowKey="id"
        columns={columns.filter((column) => column.visible)}
        dataSource={data}
        scroll={{
          x: "max-content",
          y: null,
        }}
        onChange={tableOnChange}
        size="small"
        pagination={{
          position: ["bottomCenter"],
          current: tableState.page,
          total: total,
          pageSize: tableState.limit,
          showSizeChanger: true,
        }}
        bordered
      />
      <ResellerInfoDrawer
        resellerId={openResellerInfo?.resellerId}
        open={openResellerInfo.open}
        onClose={() => setOpenResellerInfo({ open: false, resellerId: null })}
      />
    </>
  );
}
