import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Form, Input, notification } from "antd";

import Request from "../../Requests";

import { API_VERSION } from "../../configs/config";

import whiteLogo from "../../assets/white-logo.png";

import "./_Login.scss";

export default function LoginPage() {
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const fields = {
        email: {
            type: "STRING",
            rules: [
                {
                    type: "email",
                    message: "E-mail isn't valid",
                },
                {
                    required: true,
                    message: "Please input your E-mail",
                },
            ],
        },
        password: {
            type: "STRING",
            rules: [
                {
                    required: true,
                    message: "Please input your password!",
                },
                {
                    min: 8,
                    message: "Password must be minimum 8 characters.",
                },
            ],
        },
    };

    const onFinish = () => {
        const { email, password } = form.getFieldsValue();

        const query = { email: email.trim(), password: password.trim() };
        setLoading(true);
        Request.login["post"](query)
            .then((data) => {
                setLoading(false);
                localStorage.setItem("token", data);
                navigate("/dashboard/analytics");
            })
            .catch(({ message }) => {
                if (typeof message == "string") {
                    setLoading(false);
                    notification.error({ message });
                }
            });
    };

    const handleKeyPress = (event) => {
        event.preventDefault();
        if (event.key === "Enter") {
            form.submit();
        }
    };

    return (
        <div className="login-page">
            <div className="logo-login">
                <img src={whiteLogo} alt="logo" />
                <div className="version" style={{ position: "absolute", bottom: "1%" }}>
                    version | {API_VERSION}
                </div>
            </div>
            <div className="form-login">
                <div className="wrap-form">
                    <h1>Login</h1>

                    <Form
                        name="normal_login"
                        className="login-form"
                        onFinish={onFinish}
                        form={form}
                    >
                        <Form.Item name="email" rules={fields.email.rules}>
                            <Input
                                prefix={<MailOutlined className="site-form-item-icon" />}
                                placeholder="Email"
                                onPressEnter={(e) => handleKeyPress(e)}
                            />
                        </Form.Item>
                        <Form.Item name="password" rules={fields.password.rules}>
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                placeholder="Password"
                                onPressEnter={(e) => handleKeyPress(e)}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                                style={{ width: "100%" }}
                                loading={loading}
                            >
                                Log in
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
}
