import React, { useEffect, useState } from "react";
import {
  TeamOutlined,
  UserOutlined,
  DollarOutlined,
  FundProjectionScreenOutlined,
  FundViewOutlined,
  TrademarkCircleOutlined,
  UserSwitchOutlined,
  UserAddOutlined,
  ApartmentOutlined,
  GoldOutlined
} from "@ant-design/icons";
import Request from "../../../Requests";
import { useNavigate } from "react-router-dom";

export default function Cards() {
  const navigate = useNavigate();

  const [data, setData] = useState(0);
  const [cardTotal, setCardTotal] = useState(null);

  useEffect(() => {
    Request.provider
      .get({})
      .then((data) => {
        setData(data?.total);
      })
      .catch(({ message }) => {
        navigate("/");
      });
  }, []);

  useEffect(() => {
    Request.card_total_statistics()
      .then((data) => {
        setCardTotal(data);
      })
      .catch(({ message }) => {});
  }, []);

  const onCard = (account_type) => {
    navigate("/dashboard/resellers", {
      state: { accountType: account_type }
    });
  };

  return (
    <div className="list-cards">
      {/*  */}
      <div
        className="the-card card-clickable"
        onClick={() => onCard("all-reseller")}
      >
        <div>
          <TeamOutlined />
        </div>
        <div className="num-text">
          <span className="num">{data ? data : 0}</span>
          <span>All resellers</span>
        </div>
      </div>
      {/*  */}
      <div
        className="the-card card-clickable"
        onClick={() => onCard("partner")}
      >
        <div>
          <UserSwitchOutlined />
        </div>
        <div className="num-text">
          <span className="num">{cardTotal?.partners || 0}</span>
          <span> Partners </span>
        </div>
      </div>
      {/*  */}
      <div className="the-card card-clickable" onClick={() => onCard("seller")}>
        <div>
          <UserAddOutlined />
        </div>
        <div className="num-text">
          <span className="num">{cardTotal?.seller_partners || 0}</span>
          <span> Sellers </span>
        </div>
      </div>
      {/*  */}
      <div
        className="the-card card-clickable"
        onClick={() => onCard("sub_reseller")}
      >
        <div>
          <GoldOutlined />
        </div>
        <div className="num-text">
          <span className="num">{cardTotal?.total_sub_res_can_use || 0}</span>
          <span> Sub-resellers </span>
        </div>
      </div>
      {/*  */}
      <div
        className="the-card card-clickable"
        onClick={() => onCard("partner_sub_reseller")}
      >
        <div>
          <ApartmentOutlined />
        </div>
        <div className="num-text">
          <span className="num">{cardTotal?.total_sub_res_need_buy || 0}</span>
          <span> Partner sub-resellers </span>
        </div>
      </div>
      {/*  */}
      <div
        className="the-card card-clickable"
        onClick={() => onCard("reseller")}
      >
        <div>
          <UserOutlined />
        </div>
        <div className="num-text">
          <span className="num">{cardTotal?.resellers || 0}</span>
          <span>Resellers</span>
        </div>
      </div>
      {/*  */}
      <div
        className="the-card card-clickable"
        onClick={() => navigate("/dashboard/resellers")}
      >
        <div>
          <TrademarkCircleOutlined />
        </div>
        <div className="num-text">
          <span className="num">{cardTotal?.total_online_resellers || 0}</span>
          <span> Online resellers</span>
        </div>
      </div>
      {/*  */}
      <div
        className="the-card card-clickable"
        onClick={() => navigate("/dashboard/credit-transaction")}
      >
        <div>
          <DollarOutlined />
        </div>
        <div className="num-text">
          <span className="num">
            {cardTotal?.total_budget?.toFixed(2) || 0}
          </span>
          <span> Budget</span>
        </div>
      </div>
      {/*  */}
      <div
        className="the-card card-clickable"
        onClick={() => navigate("/dashboard/devices")}
      >
        <div>
          <FundProjectionScreenOutlined />
        </div>
        <div className="num-text">
          <span className="num">{cardTotal?.total_devices || 0}</span>
          <span> Devices</span>
        </div>
      </div>
      {/*  */}
      <div
        className="the-card card-clickable"
        onClick={() => navigate("/dashboard/devices")}
      >
        <div>
          <FundViewOutlined />
        </div>
        <div className="num-text">
          <span className="num">{cardTotal?.total_online_devices || 0}</span>
          <span> Online devices</span>
        </div>
      </div>

      {/*  */}
    </div>
  );
}
