import { useState } from "react";
import { Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { getBase64 } from "../../Utils";
import errorImg from "../../assets/error-image.png";


export default function FileChooser({
  count = 1,
  value = [],
  onChange = () => {},
}) {
  const [preview, setPreview] = useState({
    visible: false,
    image: "",
    title: "",
  });

  let fileList = Array.isArray(value)
    ? value
    : value
    ? [{ url: value }]
    : [];

  const showPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreview({
      visible: true,
      image: file.url || file.preview,
      title: file.name || file.url.split("/").pop(),
    });
  };

  const hidePreview = () => {
    setPreview({ visible: false, image: "", title: "" });
  };

  const handleChange = ({ fileList }) => {
    onChange(fileList);
  };

  return (
    <>
      <Upload
        customRequest={({ onSuccess }) => onSuccess()}
        listType="picture-card"
        fileList={fileList}
        onPreview={showPreview}
        onChange={handleChange}
        accept={["image/png, image/jpeg"]}
      >
        {
        fileList.length < count &&(
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        ) }
      </Upload>

      <Modal
        open={preview.visible}
        title={preview.title}
        footer={null}
        onCancel={hidePreview}
      >
        <img
          style={{ width: "100%" }}
          src={preview.image || errorImg}
          alt="img"
        />
      </Modal> 
    </>
  );
}
