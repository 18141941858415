import React from "react";
import { Layout, Button } from "antd";
import { MenuFoldOutlined } from "@ant-design/icons";
import Profile from "../profile/Profile";

import "./_Header.scss";

export default function Header({ isMobile, setOpenMenuDrawer }) {
  return (
    <Layout.Header
      className="root-header"
      style={{
        left: isMobile ? 0 : "250px",
        width: isMobile ? "100%" : `calc(100% - 250px)`,
      }}
    >
      {isMobile && (
        <Button
          type="link"
          icon={<MenuFoldOutlined />}
          onClick={() => setOpenMenuDrawer(true)}
        ></Button>
      )}
      {!isMobile && <Profile />}
    </Layout.Header>
  );
}
