import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";
import moment from "moment";
import { Card, Empty, DatePicker } from "antd";
import Request from "../../../Requests";

export default function Payment() {
  const [dataList, setDataList] = useState([
   
  ]);
  const defaultDate = `${new Date().getFullYear()}-01-01`;
  const [selectedDate, setSelectedDate] = useState(defaultDate);

  useEffect(() => {
    getData();
  }, [selectedDate]);

  const getData = () => {
    const query = {
      date: selectedDate,
    };
    Request.payment_chart(query)
      .then((data) => {
        let newData = data?.map((el)=>{
          return{
            month: el?.month,
            amount: Number(el?.amount),
            payment_method: (el?.payment_method)
          }
        })
        setDataList(newData);
      })
      .catch(() => { });
  };

  const onChangeDate = (date, dateString) => {  
    if(date){
      const selectedYear = dateString;

      const formattedString = `${selectedYear}-01-01`;
  
      setSelectedDate(formattedString);
    }else {
      setSelectedDate(new Date());
      return selectedDate;
    }
  };

  const config = {
    data: dataList && dataList,
    xField: 'month',
    yField: 'amount',
    seriesField: 'payment_method',
    isGroup: true,
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
    style:{
      minHeight: 250
    }
  };

  return (
    <Card
      title="Payments"
      extra={
        <DatePicker
          onChange={onChangeDate}
          picker={"year"}
          value={moment(selectedDate)}
        />
      }
    >
      {dataList.length > 0 ? <Column {...config} /> : <Empty />}
    </Card>
  );
}
