import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";

import { Layout, Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";

import MenuContent from "./MenuContent";

export default function Root() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);

  const isMobile = windowWidth < 800;

  useEffect(() => {
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {!isMobile ? (
        <Sidebar isMobile={isMobile} />
      ) : (
        <Drawer
          placement="left"
          width={260}
          open={open}
          closable={false}
          title={null}
          bodyStyle={{ padding: 0 }}
          getContainer={() => document.getElementById("root")}
          className="mobile-drawer-body"
        >
          <div className="sidebar" style={{ height: "100%", margin: 0 }}>
            <MenuContent onClose={() => setOpen(false)} isMobile={isMobile} />
            <div className="close-icon" onClick={() => setOpen(false)}>
              <CloseOutlined />
            </div>
          </div>
        </Drawer>
      )}
      <Layout>
        <Header isMobile={isMobile} setOpenMenuDrawer={setOpen} />

        <Layout.Content
          style={{
            marginLeft: isMobile ? 0 : "250px",
            padding: isMobile ? "30px 10px" : "30px",
          }}
        >
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  );
}
