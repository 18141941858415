import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Progress } from "antd";

import { API_VERSION } from "../../configs/config"

import whiteLogo from "../../assets/white-logo.png";

import "./_Welcome.scss";

export default function WelcomePage() {
    let interval = null;

    const navigate = useNavigate();
    
    const [percent, setPercent] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setPercent(prevCount => prevCount + 1);
        }, 10);
    
        return () => {
          clearInterval(intervalId);
        };
      }, []);

    useEffect(() => {
        setTimeout(() => {
            if (percent == 100) {
                clearInterval(interval);
                localStorage.clear();
                navigate("/login");   
            }
        }, 500);
    }, [percent]);

    return (
        <div className="welcome-page">
            <div>
                {/* className="x_cloud_icon" */}
                <img src={whiteLogo} alt="logo" /> 
                <Typography.Title style={{ color: "#fff", textAlign: "center", margin: "50px 0px" }}>Welcome to the XCLOUD</Typography.Title>
                <Progress
                    percent={percent}
                    showInfo={false}
                    strokeColor="#1890ff"
                    style={{ width: "100%" }}
                />
            </div>
            <div className="version"> version | {API_VERSION}</div>
        </div>
    );
}
