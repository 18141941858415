import React, { useEffect, useState } from "react";
import { Form, Button, Input, message } from "antd";

import Request from "../../Requests";

import { createFieldsValue } from "../../Utils";

export default function ConfigurationPage() {
  const [currentData, setCurrentData] = useState(null);

  const [disabled, setDisabled] = useState(true);

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const fields = {
    api_key: {
      type: "STRING",
      rules: [{ required: true, message: "API key is required!" }],
    },
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (currentData) {
      form.setFields([{ name: "api_key", value: currentData.api_key }]);
    }
    form.setFieldsValue(createFieldsValue(currentData, fields));
  }, [currentData]);

  const getData = () => {
    Request.subtitle
      .get()
      .then((data) => setCurrentData(data))
      .catch((err) => {});
  };

  const onFieldsChange = (changedField, allFields) => {
    if (changedField[0].name[0] == "api_key") {
      setDisabled(false);
    }
  };

  const onFinish = (values) => {
    setLoading(true);

    const body = { id: currentData.id };
    if (values.api_key !== currentData.api_key) {
      body.api_key = values.api_key;

      Request.subtitle
        .put(body)
        .then((res) => {
          setLoading(false);
          setDisabled(true);

          getData();
          message.success(res);
        })
        .catch((err) => {
          setLoading(false);
          setDisabled(true);

          message.error(err);
        });
    } else {
      message.error(
        "To update the API key, the current value must be changed!"
      );
      setLoading(false);
      setDisabled(true);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      autoComplete="off"
    >
      <Form.Item label="API key" name="api_key" rules={fields.api_key.rules}>
        <Input placeholder="api key" />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginRight: 10 }}
          loading={loading}
          disabled={disabled}
        >
          Update
        </Button>
      </Form.Item>
    </Form>
  );
}
