import React from "react";
import { List } from "antd";
import world from "../../../assets/world.png";

export default function CountryCard({ dataList }) {
  const minDataList = dataList?.slice(0, 6);
  return (
    <div
      style={{
        background: "#fff",
        width: "370px",
        height: "470px",
        padding: "0 16px",
      }}
    >
      <List
        dataSource={minDataList}
        header={<h1>Top 6 Countries</h1>}
        renderItem={(item, index) => (
          <List.Item style={{ alignItems: "baseline"}}>
            <List.Item.Meta
              avatar={<img src={item.flag} onError={(error)=> error.target.src = world} alt="" style={{width: "37px", height: "37px", borderRadius:"50%", objectFit:"cover"}}/>}
              title={item.country}
            />
            <div style={{ fontSize: "22px" }}>{item.count}</div>
          </List.Item>
        )}
      />
    </div>
  );
}
