import { useState, useEffect } from "react";

import { Table, Input, Button, notification } from "antd";

import {
  TableSettingsButton,
  TableResetButton,
  TableText,
  TableCSV,
  RangePickerFilterDropdown,
  TableDateHHMMSS,
  TitlePage,
  CopyText,
  ResellerInfoDrawer,
} from "../../components";

import Request from "../../Requests";

import { generateQuery } from "../../Utils";

import useColumns from "../../hooks/useColumns";
import useHistorySettings from "../../hooks/useHistorySettings";
import CardList from "./CardList";

export default function CreditTransactionPage() {
  const fieldsType = {
    id: "INTEGER",
    provider_id: "FILTER",
    transaktion_id: "STRING",
    count: "INTEGER",
    amount: "INTEGER",
    payment_method: "ENUM",
    status: "ENUM",
    createdAt: "DATE",
    updatedAt: "DATE",
  };

  const defaultTableState = {
    filteredInfo: {},
    sortedInfo: {},
    page: 1,
    limit: 20,
  };

  const [data, setData] = useState([]);
  const [dataForExport, setDataForExport] = useState([]);

  const [loading, setLoading] = useState(false);

  const [tableState, setTableState] = useHistorySettings(defaultTableState);

  const [total, setTotal] = useState(0);

  const [theProvider, setTheProvider] = useState(null);

  const [headSearch, setHeadSearch] = useState(null);

  const [openResellerInfo, setOpenResellerInfo] = useState({
    open: false,
    resellerId: null,
  });
  const [columns, setColumns] = useState([
    {
      title: "#",
      fixed: "left",
      key: "row__index",
      align: "center",
      render: (text, record, index) => record.index,
    },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: 70,
      align: "center",
      sorter: true,
      render: (text, record, index) => text,
    },

    {
      title: "Provider ID",
      dataIndex: "provider_id",
      key: "provider_id",
      align: "center",
      render: (text, record, index) =>
        record.provider_id ? (
          <Button
            type="ghost"
            onClick={() =>
              setOpenResellerInfo({
                open: true,
                resellerId: record?.provider_id,
              })
            }
          >
            {text}
          </Button>
        ) : (
          "N/A"
        ),
    },

    {
      title: "Spent credits",
      dataIndex: "credit",
      key: "credit",
      align: "center",
      render: (text, record, index) => <TableText text={record.credit || 0} />,
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record, index) => <TableText text={text || "N/A"} />,
      filters: [
        { text: "COMPLETED", value: "COMPLETED" },
        { text: "PENDING", value: "PENDING" },
      ],
    },
    {
      title: "Transaction ID",
      dataIndex: "transaktion_id",
      key: "transaktion_id",
      align: "center",
      render: (text, record, index) =>
        record?.provider?.transaktion_id ? (
          <CopyText
            text={<TableText text={text} style={{ color: "#1890ff" }} />}
          />
        ) : (
          "N/A"
        ),
    },
    {
      title: "Payment method",
      dataIndex: "payment_method",
      key: "payment_method",
      align: "center",
      render: (text, record, index) => (
        <TableText
          text={(
            text?.charAt(0)?.toUpperCase() + text?.slice(1)?.toLowerCase()
          )?.replaceAll("_", " ")}
        />
      ),
      filters: [
        { text: "Paypal", value: "paypal" },
        { text: "Dlocal", value: "dlocal" },
        { text: "Coinbase", value: "coinbase" },
        { text: "Ineco", value: "ineco" },
        { text: "Ameria", value: "ameria" },
        { text: "Activation", value: "activation" },
        { text: "By admin", value: "by_admin" },
      ],
    },
    {
      title: "Price",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (text, record, index) => (text ? <TableText text={text} /> : 0),
      sorter: true,
    },
    {
      title: "Updated date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
      sorter: true,
      filterDropdown: RangePickerFilterDropdown,
    },
    {
      title: "Created date",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
      sorter: true,
      filterDropdown: RangePickerFilterDropdown,
    },
  ]);
  const [onChangeColumns] = useColumns(
    columns,
    setColumns,
    "credit_transaction"
  );

  const getData = () => {
    let query = {};

    if (theProvider && headSearch) {
      tableState.filteredInfo = { provider_id: theProvider?.id };
      query = generateQuery(tableState, fieldsType);
    } else if (headSearch) {
      setData(null);
      return;
    } else {
      if (tableState.filteredInfo) {
        delete tableState.filteredInfo.provider_id;
        delete tableState.filteredInfo.brand_name;
        delete tableState.filteredInfo.name;
        delete tableState.filteredInfo.email;
      }

      setHeadSearch(null);
      setTheProvider(null);
    }
    query = generateQuery(tableState, fieldsType);

    setLoading(true);

    Request.billing_history
      .credit_transaction(query)
      .then((data) => {
        if (!data.rows.length && tableState.page > 1) {
          return setTableState({ ...tableState, page: tableState.page - 1 });
        }
        setLoading(false);
        setData(
          data.rows.map((item, index) => ({
            index: (tableState.page - 1) * tableState.limit + index + 1,
            ...item,
          }))
        );
        setTotal(data.total);
      })
      .catch(({ message }) => {
        setLoading(false);
      });
  };

  const tableOnChange = (pagination, filters, sorter) => {
    let { order, field } = sorter;

    setTableState({
      filteredInfo: filters,
      sortedInfo: { order, field },
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const resetTable = () => {
    setTableState(defaultTableState);
  };

  const onSearch = (value, _e, info) => {
    setHeadSearch(value);
  };

  useEffect(() => {
    let _columns = [...columns];
    let { filteredInfo, sortedInfo } = tableState;

    _columns.map((column) => {
      column.filteredValue = filteredInfo[column.key] || null;
      column.sortOrder = sortedInfo.field === column.key && sortedInfo.order;
    });

    setColumns(_columns);

    let timeout = setTimeout(() => {
      getData();
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [tableState, theProvider, headSearch]);

  useEffect(() => {
    if (headSearch) {
      getProvider();
    }
  }, [headSearch]);

  const getProvider = () => {
    let query = {};

    if (headSearch) {
      tableState.filteredInfo = {
        brand_name: headSearch,
        email: headSearch,
        name: headSearch,
        // app_name: headSearch,
      };

      query = generateQuery(tableState, fieldsType);

      Request.provider
        .get(query)
        .then((data) => {
          if (data?.rows?.length > 0) {
            setTheProvider(data.rows[0]);
          } else {
            setTheProvider(null);
          }
        })
        .catch(() => {});
    }
  };

  const onExport = (selected) => {
    if (selected == "allData") {
      Request.billing_history
        .credit_transaction({ query: JSON.stringify({ pagination: 0 }) })
        .then((data) => {
          setDataForExport(data);
        })
        .catch(({ message }) => {
          notification.error({ message });
        });
    }
    if (selected == "data") {
      setDataForExport(data);
    }
  };
  return (
    <>
      <TitlePage title="Credit transaction" />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: 8,
          margin: "15px 0",
        }}
      >
        <div></div>
        <div>
          <TableResetButton onClick={resetTable} />
          <TableSettingsButton
            columns={columns}
            onChangeColumns={onChangeColumns}
          />
          <TableCSV dataForExport={dataForExport} onExport={onExport} />
        </div>
      </div>

      <CardList />
      <div style={{ width: 300, margin: "20px 0px" }}>
        <Input.Search
          placeholder="Search by brand, email, name..."
          onSearch={onSearch}
          enterButton
          allowClear
        />
        <p style={{ fontSize: "10px", color: "red" }}>
          *To search, please click the search icon or press Enter.
        </p>
      </div>
      <Table
        loading={loading}
        rowKey="id"
        columns={columns.filter((column) => column.visible)}
        dataSource={data}
        scroll={{
          x: "max-content",
          y: null,
        }}
        onChange={tableOnChange}
        size="small"
        pagination={{
          position: ["bottomCenter"],
          current: tableState.page,
          total: total,
          pageSize: tableState.limit,
          showSizeChanger: true,
        }}
        bordered
      />
      <ResellerInfoDrawer
        resellerId={openResellerInfo?.resellerId}
        open={openResellerInfo.open}
        onClose={() => setOpenResellerInfo({ open: false, resellerId: null })}
      />
    </>
  );
}
