import { useState, useEffect } from "react";

import { Table, notification } from "antd";

import {
  TableSettingsButton,
  TableActions,
  TableResetButton,
  TableText,
  TableDelete,
  TableCSV,
  InputSearchFilterDropdown,
  RangePickerFilterDropdown,
  TableDate
} from "../../components";

import Request from "../../Requests";

import { generateQuery } from "../../Utils";

import useColumns from "../../hooks/useColumns";
import useHistorySettings from "../../hooks/useHistorySettings";

export default function NotificationsPage({ theProviderId }) {
  const fieldsType = {
    id: "INTEGER",
    title: "STRING",
    desc: "TEXT",
    expired_date: "DATE",
    sended_date: "TEXT",
    status: "ENUM",
    filter: "TEXT",
    total: "INTEGER",
    viewed: "INTEGER",
    provider_id: "INTEGER"
  };

  const defaultTableState = {
    filteredInfo: {},
    sortedInfo: {},
    page: 1,
    limit: 20
  };

  let heightScroll = document.body.scrollHeight - 370;

  const [data, setData] = useState([]);
  const [dataForExport, setDataForExport] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [loading, setLoading] = useState(false);

  const [tableState, setTableState] = useHistorySettings(defaultTableState);

  const [total, setTotal] = useState(0);

  const [columns, setColumns] = useState([
    {
      title: "#",
      fixed: "left",
      key: "row__index",
      render: (text, record, index) => record.index
    },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => <TableText text={text} />,
      filterDropdown: InputSearchFilterDropdown
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record, index) => <TableText text={text} />,
      filterDropdown: InputSearchFilterDropdown
    },
    {
      title: "Desc",
      dataIndex: "desc",
      key: "desc",
      render: (text, record, index) => <TableText text={text} />,
      filterDropdown: InputSearchFilterDropdown
    },
    {
      title: "Expired date",
      dataIndex: "expired_date",
      key: "expired_date",
      sorter: true,
      render: (text, record, index) => <TableDate date={text} />,
      filterDropdown: RangePickerFilterDropdown
    },
    {
      title: "Sended date",
      dataIndex: "sended_date",
      key: "sended_date",
      render: (text, record, index) => <TableText text={text} />,
      filterDropdown: InputSearchFilterDropdown
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record, index) => text,
      filters: [
        { text: "waiting", value: "waiting" },
        { text: "active", value: "active" },
        { text: "expired", value: "expired" }
      ]
    },
    {
      title: "Filter",
      dataIndex: "filter",
      key: "filter",
      render: (text, record, index) =>
        record.filter.all ? (
          record.filter.all == true ? (
            "All"
          ) : (
            "N/A"
          )
        ) : (
          <TableText text={Object.values(record.filter)[0][0]} />
        ),
      filterDropdown: InputSearchFilterDropdown
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text, record, index) => <TableText text={text} />,
      sorter: true
    },
    {
      title: "Viewed",
      dataIndex: "viewed",
      key: "viewed",
      render: (text, record, index) => <TableText text={text} />,
      sorter: true
    },

    {
      title: "Action",
      key: "row__actions",
      fixed: "right",
      width: 100,
      align: "center",
      render: (text, record, index) => (
        <TableActions showEdit={false} onDelete={deleteData} record={record} />
      )
    }
  ]);

  const [onChangeColumns] = useColumns(columns, setColumns, "notifications");

  const getData = () => {
    let query = generateQuery(tableState, fieldsType);

    if (theProviderId) {
      let providerQuery = JSON.parse(query.query);
      providerQuery = {
        ...providerQuery,
        filter: { provider_id: theProviderId }
      };
      query.query = JSON.stringify(providerQuery);
    }

    setLoading(true);

    Request.notifications
      .get(query)
      .then((data) => {
        if (!data.rows.length && tableState.page > 1) {
          return setTableState({ ...tableState, page: tableState.page - 1 });
        }
        setLoading(false);
        setData(
          data.rows.map((item, index) => ({
            index: (tableState.page - 1) * tableState.limit + index + 1,
            ...item
          }))
        );
        setTotal(data.total);
        setSelectedRowKeys([]);
      })
      .catch(({ message }) => {
        setLoading(false);
      });
  };

  const deleteData = ({ id }) => {
    Request.notifications
      .delete({ id })
      .then(() => {
        setTableState((prev) => {
          return { ...prev };
        });
      })
      .catch(({ message }) => {
        notification.error({ message });
      });
  };

  const deleteSelecteds = () => {
    deleteData({ id: selectedRowKeys });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const tableOnChange = (pagination, filters, sorter) => {
    let { order, field } = sorter;

    setTableState({
      filteredInfo: filters,
      sortedInfo: { order, field },
      page: pagination.current,
      limit: pagination.pageSize
    });
  };

  const resetTable = () => {
    setTableState(defaultTableState);
  };

  useEffect(() => {
    let _columns = [...columns];
    let { filteredInfo, sortedInfo } = tableState;

    _columns.map((column) => {
      column.filteredValue = filteredInfo[column.key] || null;
      column.sortOrder = sortedInfo.field === column.key && sortedInfo.order;
    });

    setColumns(_columns);

    getData();
  }, [tableState, theProviderId]);

  const onExport = (selected) => {
    if (selected == "allData") {
      Request.notifications
        .get({ query: JSON.stringify({ pagination: 0 }) })
        .then((data) => {
          setDataForExport(data);
        })
        .catch(({ message }) => {
          notification.error({ message });
        });
    }
    if (selected == "data") {
      setDataForExport(data);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 8,
          marginBottom: 20
        }}
      >
        <TableDelete
          disabled={!selectedRowKeys.length}
          onDelete={deleteSelecteds}
        />
        <TableResetButton onClick={resetTable} />
        <TableSettingsButton
          columns={columns}
          onChangeColumns={onChangeColumns}
        />
        <TableCSV dataForExport={dataForExport} onExport={onExport} />
      </div>

      <Table
        loading={loading}
        rowKey="id"
        columns={columns.filter((column) => column.visible)}
        dataSource={data}
        scroll={{
          x: "max-content",
          y: data?.length > 0 ? heightScroll : null
        }}
        onChange={tableOnChange}
        size="small"
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange
        }}
        pagination={{
          position: ["bottomCenter"],
          current: tableState.page,
          total: total,
          pageSize: tableState.limit,
          showSizeChanger: true
        }}
      />
    </div>
  );
}
