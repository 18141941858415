import { useEffect, useState } from "react";
import Request from "../../Requests";
import {
  Drawer,
  Form,
  Button,
  notification,
  Input,
  DatePicker,
  Select,
  Switch,
  Checkbox,
} from "antd";
import moment from "moment";

import { createFieldsValue } from "../../Utils";

import {
  useBrandsOptions,
  useCountrysOptions,
  useProviderEmailsOptions,
} from "../../hooks/useSelctOptions";

export default function AlertsDrawer({ visible, onClose, selectedRow }) {
  const fields = {
    email: {
      type: "STRING",
      rules: [{ required: true, message: "Email is required!" }],
    },
    brand_name: {
      type: "ENUM",
      rules: [{ required: true, message: "Brand name is required!" }],
    },
    country: {
      type: "ENUM",
      rules: [{ required: true, message: "Country is required!" }],
    },
    desc: {
      type: "STRING",
      rules: [{ required: true, message: "Description is required!" }],
    },
    send_from: {
      type: "DATE",
      rules: [{ required: true, message: "Send from is required!" }],
    },
    send_to: {
      type: "DATE",
      rules: [{ required: true, message: "Send to is required!" }],
    },
    seen_from: {
      type: "DATE",
      rules: [{ required: true, message: "Send from is required!" }],
    },
    seen_to: {
      type: "DATE",
      rules: [{ required: true, message: "Send from is required!" }],
    },
    is_enable: {
      type: "BOOLEAN",
      rules: [{ required: false, message: "" }],
    },
  };

  const [checkboxValues, setCheckboxValues] = useState({
    email: false,
    brand_name: false,
    country: false,
    registered_date: false,
  });

  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const brandsOptions = useBrandsOptions();
  const countrysOptions = useCountrysOptions();
  const providerEmailsOptions = useProviderEmailsOptions();

  const disabledDateFrom = (current, defaultValue) => {
    return current && current < defaultValue.startOf("day");
  };

  const disabledDateTo = (current, defaultValue) => {
    return current && current < defaultValue.endOf("day");
  };

  const onChange = (e) => {
    if (e.target.name == "email") {
      setCheckboxValues({
        email: e.target.checked,
        brand_name: false,
        country: false,
        registered_date: false,
      });
    } else {
      setCheckboxValues({
        ...checkboxValues,
        [e.target.name]: e.target.checked,
      });
    }
  };

  const onFinish = (values) => {
    let method = "post";
    setLoading(true);
    if (selectedRow) {
      method = "put";
      values.id = selectedRow.id;
      if (selectedRow?.seen_from == values?.seen_from?._i) {
        delete values?.seen_from;
      }

      if (selectedRow?.seen_to == values?.seen_to?._i) {
        delete values?.seen_to;
      }
    }
    Request.alerts[method](values)
      .then(() => {
        setLoading(false);
        hideDrawer(true);
      })
      .catch(({ message }) => {
        setLoading(false);
        notification.error({ message });
      });
  };

  const hideDrawer = (changed) => {
    onClose(changed);
    form.resetFields();
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(createFieldsValue(selectedRow, fields));
    } else {
      setCheckboxValues(null);
    }
  }, [visible, selectedRow]);

  useEffect(() => {
    if (selectedRow?.email) {
      setCheckboxValues({ ...checkboxValues, email: true });
    }
    if (selectedRow?.brand_name) {
      setCheckboxValues({ ...checkboxValues, brand_name: true });
    }
    if (selectedRow?.country) {
      setCheckboxValues({ ...checkboxValues, country: true });
    }
    if (selectedRow?.send_from || selectedRow?.send_to) {
      setCheckboxValues({ ...checkboxValues, registered_date: true });
    }
  }, [selectedRow]);

  useEffect(() => {
    if (
      checkboxValues?.email ||
      checkboxValues?.brand_name ||
      checkboxValues?.country ||
      checkboxValues?.registered_date
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [checkboxValues]);

  return (
    <Drawer
      title={selectedRow ? "Update" : "Add"}
      placement="right"
      onClose={() => hideDrawer(false)}
      open={visible}
      width={600}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "baseline",
          marginBottom: "15px",
        }}
      >
        <p>
          <span
            style={{
              color: "#ff4d4f",
              fontSize: "10px",
              position: "absolute",
              left: "22px",
            }}
          >
            {" "}
            *{" "}
          </span>
          Select what devices you must send alerts:
        </p>

        <Checkbox
          onChange={onChange}
          name="email"
          checked={checkboxValues?.email}
        >
          Email
        </Checkbox>
        <Checkbox
          onChange={onChange}
          name="brand_name"
          disabled={checkboxValues?.email}
          checked={checkboxValues?.brand_name}
        >
          Brand name
        </Checkbox>

        <Checkbox
          onChange={onChange}
          name="country"
          disabled={checkboxValues?.email}
          checked={checkboxValues?.country}
        >
          Country
        </Checkbox>
        <Checkbox
          onChange={onChange}
          name="registered_date"
          disabled={checkboxValues?.email}
          checked={checkboxValues?.registered_date}
        >
          Registered Date
        </Checkbox>
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        {checkboxValues?.email && (
          <Form.Item label="Email" name="email" rules={fields.email.rules}>
            <Select
              showSearch
              showArrow
              allowClear
              options={providerEmailsOptions}
              placeholder="Select email"
              optionFilterProp="value"
              filterOption={(input, option) =>
                option?.value?.toLowerCase()?.includes(input?.toLowerCase())
              }
            />
          </Form.Item>
        )}
        {checkboxValues?.brand_name && !checkboxValues?.email && (
          <Form.Item
            label="Brand name"
            name="brand_name"
            rules={fields.brand_name.rules}
          >
            <Select
              showSearch
              showArrow
              allowClear
              options={brandsOptions}
              placeholder="Select brand"
              optionFilterProp="value"
              filterOption={(input, option) =>
                option?.value?.toLowerCase()?.includes(input?.toLowerCase())
              }
            />
          </Form.Item>
        )}
        {checkboxValues?.country && !checkboxValues?.email && (
          <Form.Item
            label="Country"
            name="country"
            rules={fields.country.rules}
          >
            <Select
              showSearch
              showArrow
              allowClear
              options={countrysOptions}
              placeholder="Select country"
              optionFilterProp="value"
              filterOption={(input, option) =>
                option?.value?.toLowerCase()?.includes(input?.toLowerCase())
              }
            />
          </Form.Item>
        )}
        {checkboxValues?.registered_date && !checkboxValues?.email && (
          <>
            <p>Registered Date:</p>
            <div style={{ display: "flex", gap: 30 }}>
              <Form.Item
                label="From"
                name="send_from"
                rules={fields.send_from.rules}
              >
                <DatePicker allowClear placeholder="Send from" />
              </Form.Item>

              <Form.Item label="To" name="send_to" rules={fields.send_to.rules}>
                <DatePicker allowClear placeholder="Send to" />
              </Form.Item>
            </div>
          </>
        )}

        <Form.Item label="Description" name="desc" rules={fields.desc.rules}>
          <Input.TextArea allowClear placeholder="Description" />
        </Form.Item>
        <div style={{ display: "flex", gap: 30 }}>
          <Form.Item
            label="Send from"
            name="seen_from"
            rules={fields.send_from.rules}
          >
            <DatePicker
              allowClear
              defaultValue={moment(selectedRow?.seen_from)}
              placeholder="Seen from"
              disabledDate={(current) =>
                disabledDateFrom(current, moment(selectedRow?.seen_from))
              }
            />
          </Form.Item>

          <Form.Item
            label="Send to"
            name="seen_to"
            rules={fields.send_to.rules}
          >
            <DatePicker
              allowClear
              defaultValue={moment(selectedRow?.seen_to)}
              placeholder="Seen to"
              disabledDate={(current) =>
                disabledDateTo(current, moment(selectedRow?.seen_to))
              }
            />
          </Form.Item>
        </div>

        {selectedRow && (
          <Form.Item
            label="Is enable"
            name="is_enable"
            rules={fields.is_enable.rules}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        )}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={disabled}
            loading={loading}
          >
            {selectedRow ? "Update" : "Add"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
