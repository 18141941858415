import React, { useState } from "react";
import { Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import errorImg from "../../assets/error-image.png";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export default function FileUpload({ path, getNewPath, name }) {
  const [loading, setLoading] = useState(false);

  const changeLogo = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        getNewPath(url, name, info.file.originFileObj);     
        setLoading(false);
      });
    }
  };
  return (
    <Upload
      accept={["image/png, image/jpeg"]}
      customRequest={({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      }}
      listType="picture-card"
      showUploadList={false}
      maxCount={1}
      onChange={changeLogo}
    >
      {path ? (
        <img
          src={path}
          alt="logo"
          style={{
            width: "100%",
          }}
          onError={(e) => (e.target.src = errorImg)}
        />
      ) : (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            Upload
          </div>
        </div>
      )}
    </Upload>
  );
}
