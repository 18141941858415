import React from "react";

import { Tabs } from "antd";

import { TitlePage } from "../../components";

import LiveTvPage from "./LiveTvPage";
import MoviesPage from "./MoviesPage";
import SeriesPage from "./SeriesPage";

export default function ErrorLogs() {
  const onChange = (key) => {};

  const items = [
    {
      key: "live_tv",
      label: "Live TV",
      children: <LiveTvPage />,
    },
    {
      key: "movies",
      label: "Movies",
      children: <MoviesPage />,
    },
    {
      key: "series",
      label: "Series",
      children: <SeriesPage />,
    },
  ];
  return (
    <>
      <TitlePage title="Error logs" />
      <Tabs defaultActiveKey="live_tv" items={items} onChange={onChange} />
    </>
  );
}
