import React from "react";
import { Tabs } from "antd";
import { TitlePage } from "../../components";

import ConfigurationPage from "./ConfigurationPage";

export default function SettingsPage() {
    const onChange = (key) => {};
    const items = [
        {
            key: "configuration",
            label: "Configuration",
            children: <ConfigurationPage />,
        },
    ];
    return (
        <>
            <TitlePage title="Settings" />

            <Tabs
                defaultActiveKey="configuration"
                tabPosition="left"
                items={items}
                onChange={onChange}
            />
        </>
    );
}
