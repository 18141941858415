import { useEffect, useRef, useState } from "react";

import Request from "../../Requests";

import { Drawer, Form, Button, notification, Input, Select } from "antd";
import { FileChooser } from "../../components";

const languages = [
  { iso_code: "en", name: "English" },
  { iso_code: "pt", name: "Portuguese" },
  { iso_code: "sq", name: "Albanian" },
];

export default function NewsDrawer({ visible, onClose, selectedRow }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const translation = useRef(null);

  useEffect(() => {
    if (visible) {
      if (selectedRow) {
          const txtEn = selectedRow?.news_translations?.find(
            (item) => item.iso_code === "en"
          );

          if (txtEn) {
            form.setFieldsValue({
              title: txtEn.title,
              description: txtEn.description,
              iso_code: txtEn.iso_code,
              image: selectedRow.image,
            });
          } else {
            const languages = ["sq", "pt"];
            const translation = selectedRow?.news_translations?.find((item) =>
              languages.includes(item.iso_code)
            );
              form.setFieldsValue({
                title: translation.title,
                description: translation.description,
                iso_code: translation.iso_code,
                image: selectedRow.image,
              });
          }
      }
    } else {
      form.resetFields();
    }
  }, [visible, selectedRow]);

  const onFinish = (values) => {
    let method = "post";
    const { title, description, iso_code, image } = values;

    setLoading(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("iso_code", iso_code);

    if (typeof image === "object") {
      formData.append("image", image[0]?.originFileObj);
    }

    if (selectedRow) {
      method = "put";
      formData.append("id", selectedRow.id);
    }

    Request.news[method](formData)
      .then(() => {
        setLoading(false);
        hideDrawer(true);
        const languageName = languages.find(
          (item) => item.iso_code === iso_code
        )?.name;
        notification.success({
          message: "News for " + languageName + " saved successfully",
        });
      })
      .catch(({ message }) => {
        setLoading(false);
        notification.error({ message });
      });
  };

  const hideDrawer = (changed) => {
    onClose(changed);
    form.resetFields();
  };

  const returnSelectedTranslations = (val) => {
    if (selectedRow) {

      translation.current = selectedRow?.news_translations?.find(
        (item) => item.iso_code === val
      );
    
      if (translation?.current){
        form.setFieldsValue({
          title: translation?.current?.title,
          description: translation?.current?.description,
          iso_code: translation?.current?.iso_code,
          image: selectedRow?.image,
        });
      } else {
        form.setFieldsValue({
          title: "",
          description: "",
          iso_code: val,
          image: selectedRow?.image,
        });
      }
    }
  };
  return (
    <Drawer
      title={selectedRow ? "Update" : "Add"}
      placement="right"
      onClose={() => hideDrawer(false)}
      open={visible}
      className="drawer-content--middle-width"
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          iso_code: "en",
        }}
      >
        <Form.Item
          label="Image"
          name="image"
          rules={[
            {
              required: !selectedRow,
              message: "Image is required!",
            },
          ]}
        >
          <FileChooser style={{ width: 400 }} />
        </Form.Item>

        <Form.Item label="Language" name="iso_code">
          <Select
            placeholder="Status"
            onChange={(val) => returnSelectedTranslations(val)}
          >
            <Select.Option value="en">🇺🇸 English</Select.Option>
            <Select.Option value="pt">🇧🇷 Portuguese</Select.Option>
            <Select.Option value="sq">🇦🇱 Albanian</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: !selectedRow,
              message: "Title is required!",
            },
          ]}
        >
          <Input placeholder="Title" />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: !selectedRow,
              message: "Description is required!",
            },
          ]}
        >
          <Input.TextArea
            placeholder="Description"
            rows={10}
            style={{ resize: "none" }}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {selectedRow ? "Update" : "Add"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
