import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import React from "react";

export default function confirmModal({
  title,
  content,
  okText = "yes",
  cancelText = "cancel",
  icon = <ExclamationCircleOutlined />,
  action,
  className,
}) {
  return Modal.confirm({
    className: className,
    title: title,
    icon: icon,
    content: content,
    okText: okText,
    okType: "danger",
    cancelText: cancelText,
    onOk: action,
    // onCancel: cancel,
  });
}
