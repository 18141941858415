import React, { useEffect, useState } from "react";
import { message } from "antd";

import Request from "../../Requests";

import moneyPng from "../../assets/money.png";

import "./statistics.scss";

export default function CardList() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    Request.billing_history
      .total_sum()
      .then((data) => setData(data))
      .catch((err) => message.error(err));
  };
  return (
    <>
      <h3>Total counts:</h3>
      <div className="statistics-list">
        {data?.map((el, i) => {
          return (
            <div className="statistic-component" key={i}>
              <div className="info">
                <p className="title">
                  {el?.payment_method?.replace(/_/g, " ")}
                </p>
                <p className="value">{el.sum?.replace(/-/g, "")}</p>
              </div>

              <img src={moneyPng} alt="money" />
            </div>
          );
        })}
      </div>
    </>
  );
}
