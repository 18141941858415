import { useState } from "react";
import { Tabs } from "antd";
import ResellerDevice from "./ResellerDevice";
import Deactivated from "./Deactivated";

export default function DevicesTab({ theProviderId, deactivatedAllDevices }) {
  const [activeKey, setActiveKey] = useState("All");

  const items = [
    {
      key: "All",
      label: "All",
      children: (
        <ResellerDevice
          theProviderId={theProviderId}
          deactivatedAllDevices={deactivatedAllDevices}
        />
      ),
    },

    {
      key: "Deactivated history",
      label: "Deactivated history",
      children: <Deactivated theProviderId={theProviderId} />,
    },
  ];

  return (
    <Tabs
      items={items}
      activeKey={activeKey}
      onChange={(key) => setActiveKey(key)}
    />
  );
}
