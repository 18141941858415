import { useState, useEffect, useRef } from "react";
import moment from "moment";

import { Table, Tag, Button, message, DatePicker, Modal } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  StopOutlined,
  DollarOutlined,
} from "@ant-design/icons";

import { getColumnSearchProps } from "../../configs/config";

import {
  TableSettingsButton,
  TableResetButton,
  CopyText,
  TableText,
  RangePickerFilterDropdown,
  TableDateHHMMSS,
  TitlePage,
  ActionTable,
  confirmModal,
  TableBoolean,
  ResellerInfoDrawer,
} from "../../components";

import Request from "../../Requests";

import { generateQuery } from "../../Utils";

import useColumns from "../../hooks/useColumns";

import BannerAdDrawer from "./BannerAdDrawer";

export default function BannerAd() {
  const fieldsType = {
    brand_name: "STRING",
    email: "STRING",
    status: "ENUM",
    schedule: "ENUM",
    payed: "ENUM",
    start_date: "DATE",
    end_date: "DATE",
    createdAt: "DATE",
  };

  const defaultTableState = {
    filteredInfo: {},
    sortedInfo: {},
    page: 1,
    limit: 20,
  };

  const [data, setData] = useState([]);

  const [currentData, setCurrentData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [isPayOpen, setIsPayOpen] = useState(false);

  const [tableState, setTableState] = useState(defaultTableState);

  const [total, setTotal] = useState(0);

  const [openResellerInfo, setOpenResellerInfo] = useState({
    open: false,
    resellerId: null,
  });

  const [columns, setColumns] = useState([
    {
      title: "#",
      width: 40,
      dataIndex: "index",
      key: "index",
      fixed: "left",
      align: "center",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Provider ID",
      dataIndex: "provider_id",
      key: "provider_id",
      align: "center",
      render: (text, record, index) =>
        record.provider_id ? (
          <Button
            type="ghost"
            onClick={() =>
              setOpenResellerInfo({
                open: true,
                resellerId: record?.provider_id,
              })
            }
          >
            {text}
          </Button>
        ) : (
          "N/A"
        ),
    },

    {
      title: "Brand name",
      dataIndex: "brand_name",
      key: "brand_name",
      align: "center",
      ...getColumnSearchProps(),
      render: (text, record, index) =>
        record?.brand_name ? (
          <CopyText
            text={<TableText text={text} style={{ color: "#1890ff" }} />}
          />
        ) : (
          "N/A"
        ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      ...getColumnSearchProps(),
      render: (text, record, index) =>
        record?.email ? (
          <CopyText
            text={<TableText text={text} style={{ color: "#1890ff" }} />}
          />
        ) : (
          "N/A"
        ),
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      align: "center",
      render: (text, record, index) =>
        text ? <TableText text={text} /> : "N/A",
    },

    {
      title: "Video",
      dataIndex: "video",
      key: "video",
      align: "center",
      render: (text, record, index) =>
        text ? <TableText text={text} /> : "N/A",
    },

    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "center",
      render: (text, record, index) =>
        record?.title ? <TableText text={text} /> : "N/A",
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      render: (text, record, index) =>
        record.description ? <TableText text={text} /> : "N/A",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      filters: [
        {
          text: "Inactive",
          value: "inactive",
        },
        {
          text: "Active",
          value: "active",
        },
      ],
      render: (text, record, index) =>
        record.status == "inactive" ? (
          <Tag color="gold">
            {(
              text?.charAt(0)?.toUpperCase() + text?.slice(1)?.toLowerCase()
            )?.replaceAll("_", " ")}
          </Tag>
        ) : (
          <Tag color="green">
            {(
              text?.charAt(0)?.toUpperCase() + text?.slice(1)?.toLowerCase()
            )?.replaceAll("_", " ")}
          </Tag>
        ),
    },

    {
      title: "Schedule",
      dataIndex: "schedule",
      key: "schedule",
      align: "center",
      filters: [
        {
          text: "1 Day",
          value: "daily",
        },
        {
          text: "1 Week",
          value: "weekly",
        },
        {
          text: "1 Month",
          value: "month",
        },
      ],
      render: (text, record, index) =>
        record.schedule === "daily"
          ? "1 Day"
          : record.schedule === "weekly"
          ? "1 Week"
          : "1 Month",
    },

    {
      title: "Payment status",
      dataIndex: "payed",
      key: "payed",
      align: "center",
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      render: (text, record, index) => <TableBoolean value={text} />,
    },
    {
      title: "Start date",
      dataIndex: "start_date",
      key: "start_date",
      align: "center",
      filterDropdown: RangePickerFilterDropdown,
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
    },
    {
      title: "End date",
      dataIndex: "end_date",
      key: "end_date",
      align: "center",
      filterDropdown: RangePickerFilterDropdown,
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
    },

    {
      title: "Created date",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      filterDropdown: RangePickerFilterDropdown,
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
    },
    {
      title: "Actions",
      key: "row__actions",
      fixed: "right",
      width: 100,
      render: (text, record, index) => (
        <ActionTable
          handleMenuClick={(e) => handleMenuClick(e, record)}
          buttons={[
            {
              key: "pay",
              label: "Pay",
              icon: <DollarOutlined />,
            },
            {
              key: "edit",
              label: "Edit",
              icon: <EditOutlined />,
            },
            {
              key: "deactivate",
              label: "Deactivate",
              icon: <StopOutlined />,
              disabled: record.status == "inactive",
            },
            {
              key: "delete",
              label: "Delete",
              icon: <DeleteOutlined />,
            },
          ]}
        />
      ),
    },
  ]);

  const [onChangeColumns] = useColumns(columns, setColumns, "billing_history");

  const disabledDateFrom = (current, defaultValue) => {
    return current && current < defaultValue.startOf("day");
  };
  const onChangeStartDate = (date, dateString) => {
    setStartDate(dateString);
  };
  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "pay":
        setIsPayOpen(true);
        setCurrentData(item);
        break;
      case "edit":
        setIsDrawerOpen(true);
        setCurrentData(item);
        break;
      case "deactivate":
        confirmModal({
          title: "Are you sure to deactivate this item ?",
          action: () => {
            Request.banner_ad
              .deactivate({ id: item?.id })
              .then((res) => {
                message.success(res);
                getData();
              })
              .catch((err) => {
                message.error(err);
              });
          },
        });
        break;
      case "delete":
        confirmModal({
          title: "Are you sure to delete this item?",
          action: () => {
            Request.banner_ad
              .delete({ id: item.id })
              .then((res) => {
                message.success(res);
                getData();
              })
              .catch((err) => {
                message.error(err);
              });
          },
        });
        break;
    }
  };

  const getData = () => {
    let query = generateQuery(tableState, fieldsType);

    setLoading(true);

    Request.banner_ad
      .get(query)
      .then((data) => {
        if (!data.rows.length && tableState.page > 1) {
          return setTableState({ ...tableState, page: tableState.page - 1 });
        }

        setLoading(false);
        setData(
          data.rows.map((item, index) => ({
            index: (tableState.page - 1) * tableState.limit + index + 1,
            ...item,
          }))
        );
        setTotal(data.total);
      })
      .catch(({ message }) => {
        setLoading(false);
      });
  };

  const tableOnChange = (pagination, filters, sorter) => {
    let { order, field } = sorter;

    setTableState({
      filteredInfo: filters,
      sortedInfo: { order, field },
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const resetTable = () => {
    setTableState(defaultTableState);
  };

  useEffect(() => {
    let _columns = [...columns];
    let { filteredInfo, sortedInfo } = tableState;

    _columns.map((column) => {
      column.filteredValue = filteredInfo[column.key] || null;
      column.sortOrder = sortedInfo.field === column.key && sortedInfo.order;
    });

    setColumns(_columns);
    const timeout = setTimeout(() => {
      getData();
    }, 500);
    return () => clearTimeout(timeout);
  }, [tableState]);

  const onPay = () => {
    const body = {
      id: currentData.id,
    };

    if (startDate) {
      body.start_date = startDate;
    }

    Request.banner_ad
      .pay(body)
      .then((res) => {
        setIsPayOpen(false);
        setCurrentData(null);

        getData();

        message.success(res);
      })
      .catch((err) => {
        message.error(err);
      });
  };

  return (
    <>
      <TitlePage title="Banner AD" />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: 8,
          marginBottom: 15,
        }}
      >
        <div>
          <TableResetButton onClick={resetTable} />
          <TableSettingsButton
            columns={columns}
            onChangeColumns={onChangeColumns}
          />
        </div>
      </div>
      <Table
        loading={loading}
        rowKey="id"
        columns={columns.filter((column) => column.visible)}
        dataSource={data}
        scroll={{
          x: "max-content",
          y: null,
        }}
        onChange={tableOnChange}
        size="small"
        pagination={{
          position: ["bottomCenter"],
          current: tableState.page,
          total: total,
          pageSize: tableState.limit,
          showSizeChanger: true,
        }}
        bordered
      />

      <BannerAdDrawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        current={currentData}
        getData={getData}
      />

      <Modal
        title="Pay Banner AD"
        open={isPayOpen}
        onCancel={() => setIsPayOpen(false)}
        onOk={onPay}
        okText="Pay"
        closeIcon={false}
        closable={false}
      >
        <p className="lable">Start date</p>
        <DatePicker
          disabledDate={(current) =>
            disabledDateFrom(current, moment(currentData?.seen_from))
          }
          onChange={onChangeStartDate}
          style={{ width: 200 }}
        />
      </Modal>

      <ResellerInfoDrawer
        resellerId={openResellerInfo?.resellerId}
        open={openResellerInfo.open}
        onClose={() => setOpenResellerInfo({ open: false, resellerId: null })}
      />
    </>
  );
}
