import { Layout } from "antd";

import MenuContent from "../MenuContent";

import "./_Sidebar.scss";

export default function Sidebar({ isMobile }) {
  return (
    <Layout.Sider
      id="sidebar"
      style={{ marginRight: isMobile ? 0 : 250 }}
      breakpoint="md"
      collapsedWidth="0"
    >
      <MenuContent />
    </Layout.Sider>
  );
}
